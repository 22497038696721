(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleSearchOnDirrect();
    handleSearchOnGroup();
    handleSearchOnZeroGroup();
    handleSearchContactList();
    handleSeachForward();
  }

  var handleSeachForward = function() {
    $(".input-search-forward").on('keyup input', function () {
      var filter = $(this).val();
      $("ul.forward-group-list li").each(function () {
          if ($(this).text().search(new RegExp(filter, "i")) < 0) {
              $(this).hide();
          } else {
              $(this).show()
          }
      });
    });
  }

  var handleSearchContactList = function() {
    $("#pills-contacts").delegate(".input-search-contact-list", "keyup input", function () {
      var filter = $(this).val();
      $("ul.contact-list li").each(function () {
          if ($(this).text().search(new RegExp(filter, "i")) < 0) {
              $(this).hide();
          } else {
              $(this).show()
          }
      });
    });
  }

  var handleSearchOnDirrect = function() {
    $("#pills-chat").delegate(".input-search-dirrect-chat", "keyup input", function () {
      var filter = $(this).val();
      $("ul.chat-user-list li").each(function () {
          if ($(this).text().search(new RegExp(filter, "i")) < 0) {
              $(this).hide();
          } else {
              $(this).show()
          }
      });
    });
  }

  var handleSearchOnGroup = function() {
    $("#pills-groups").delegate(".input-search-group-chat", "keyup input", function () {
      var filter = $(this).val();
      $("ul.chat-group-list li").each(function () {
          if ($(this).text().search(new RegExp(filter, "i")) < 0) {
              $(this).hide();
          } else {
              $(this).show()
          }
      });
    });
  }

  var handleSearchOnZeroGroup = function() {
    $("#pills-zero-groups").delegate(".input-search-zero-group", "keyup input", function () {
      var filter = $(this).val();
      $("ul.chat-zero-group-list li").each(function () {
          if ($(this).text().search(new RegExp(filter, "i")) < 0) {
              $(this).hide();
          } else {
              $(this).show()
          }
      });
    });
  }
}).call(this);
