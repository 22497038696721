(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    copyChekID();
    copyMyChekId();
  }

  var copyMyChekId = function() {
    $(".copy-my-chek-id-btn").on("click", function() {
      var $temp = $("<input>");
      $("body").append($temp);
      $temp.val($(".copy-my-chek-id-btn .chek-id-value").text()).select();
      document.execCommand("copy");
      $temp.remove();

      $(".toast-body").text("Already copied LinkID to clipboard");
      $(".toast-block").show();
      $('.toast').toast({ delay: 3000})
      $('.toast').toast("show")
      $(".toast-block").animate({width:'show'}, 3000);
    })
  }

  var copyChekID = function() {
    $(".chek-id-group").on("click", function() {
      var $temp = $("<input>");
      $("body").append($temp);
      $temp.val($(this).find(".chek-id-value").text()).select();
      document.execCommand("copy");
      $temp.remove();

      $(".toast-body").text("Already copied LinkID to clipboard");
      $(".toast-block").show();
      $('.toast').toast({ delay: 3000})
      $('.toast').toast("show")
      $(".toast-block").animate({width:'show'}, 3000);
    })
  }
}).call(this);


