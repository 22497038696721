(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleSelectAvatar()
    handleDeleteAvatar()
  }

  var handleDeleteAvatar = function() {
    $("#edit-avatar .delete-btn").on("click", function() {
      const defaultAvatar = $("#default_avatar").val()
      $("#edit-avatar .show-image-block .avatar-img").attr("src", defaultAvatar)
      $("#edit-avatar .set-avatar").val("delete")
    })
  }

  var handleSelectAvatar = function() {
    $("#edit-avatar .list .each-item").on("click", function() {
      const imgPath = $(this).find("img").attr("src")

      $("#edit-avatar .show-image-block .avatar-img").attr("src", imgPath)
      $("#edit-avatar .set-avatar").val(imgPath)
      $(".main-content").animate({scrollTop: 0}, 500)
    })
  }
}).call(this);
