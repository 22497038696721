var wakeLock;
(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleWakeFeature()
    handleStopWake()
  }

  var handleWakeFeature = function() {
    $("#pills-chat, #pills-groups, #pills-zero-groups").delegate("ul.room-list li", "click", function(e) {
      startAwake();
    })
  }

  var handleStopWake = function() {
    $(".user-chat-remove").on("click", function() {
      try {
        wakeLock.release()
      } catch (exception_var) {
        console.log(exception_var)
      }
    })
  }

  async function startAwake() {
    try {
      wakeLock = await navigator.wakeLock.request('screen');
    } catch (err) {
      console.log(err)
    }
  }
}).call(this);
