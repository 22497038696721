var recorder, audio_stream, timeout_status, wavesurfer, micWavesurfer, recordTimer, blobObject;
(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleRecord();
    handleStop();
    handlePlay();
    handlePause();
    handleBackOrClose();
    handleSendVoice();
  }

  var handleStop = function() {
    $(".voice-record-main .stop-btn").on("click", function() {
      clearInterval(recordTimer);
      $(".play-btn").show();
      $(".stop-btn").hide();
      $(".pulsating-circle").hide();
      $(".record-text").hide();
      $(".time-text").css("right", "12px");
      stopRecording();

      wavesurfer = WaveSurfer.create({
        container: '#waveform',
        waveColor: '#39ADAA',
        progressColor: '#39ADAA'
      });
      wavesurfer.setHeight(36.5)

      setTimeout(function() {
        const url = $("#audio-playback").attr("src");
        wavesurfer.load(url);
      }, 100);
      micWavesurfer.destroy();
      clearTimeout(timeout_status)

      wavesurfer.on("pause", function() {
        $(".voice-record-main .play-btn").show();
        $(".voice-record-main .pause-btn").hide();
      });
    })
  }

  var handlePlay = function() {
    $(".voice-record-main .play-btn").on("click", function() {
      $(".voice-record-main .play-btn").hide();
      $(".voice-record-main .pause-btn").show();
      wavesurfer.play();
    });
  }

  var handlePause = function() {
     $(".voice-record-main .pause-btn").on("click", function() {
      wavesurfer.pause();
    });
  }

  var handleRecord = function() {
    $(".start-record").on("long-press", async function() {
      let allowMic = true;
      try {
        const micPermission = Permission.checkMicPermission()
        if (!micPermission) {
          allowMic = false;
          Permission.voicePermission();
          return false;
        }
      } catch (err) {
      }

      if (allowMic) {
        $(".play-btn").hide();
        $(".voice-record-main .pause-btn").hide();
        $(".stop-btn").show();
        $(".pulsating-circle").show();
        $(".record-text").show();

        $(".voice-record-main").show();
        $(".enter-messsage-content").hide();
        $(".tool-box").hide();

        $(".time-text").css("right", "38px");
        startTimer();
        startRecording();
        micWavesurfer = WaveSurfer.create({
          container: '#waveform',
          waveColor: '#39ADAA',
          interact:   false,
          cursorWidth: 0,
          plugins: [
            WaveSurfer.microphone.create()
          ]
        });
        micWavesurfer.setHeight(36.5)
        micWavesurfer.microphone.start();
      }
    })
  }

  var handleBackOrClose = function() {
    $(".voice-record-main .delete-btn, .user-chat-remove").on("click", function() {
      if (typeof(recordTimer) != "undefined") {
        $(".voice-record-main").hide();
        $(".enter-messsage-content").show();
        $(".tool-box").show();
        clearTimer();
        stopRecording();
        if (wavesurfer) {
          wavesurfer.destroy();
        }
        micWavesurfer.destroy();
        clearTimeout(timeout_status)
      }
    })
  }

  var handleSendVoice = function() {
    $("#message-content .send-voice-btn").on("click", function() {
      const room_id = $("#message-content input[name=room_id]").val();
      if (recorder.state === "recording") {
        clearTimer()
        stopRecording();
      }

      $(".voice-record-main").hide();
      $(".enter-messsage-content").show();
      $(".tool-box").show();
      if (wavesurfer) {
        wavesurfer.destroy();
      }
      if (micWavesurfer) {
        micWavesurfer.destroy();
      }
      clearTimeout(timeout_status)

      storeData(room_id);
    })
  }

  function storeData(room_id) {
    var result;
    var base64data;
    var reader = new FileReader();
    if (blobObject.size) {
      reader.readAsDataURL(blobObject);
      reader.onloadend = function() {
        base64data = reader.result;
        sendAjax(base64data, room_id);
      }
    } else {
      recorder.ondataavailable = function (e) {
        reader.readAsDataURL(e.data);
        reader.onloadend = function() {
          base64data = reader.result;
          sendAjax(base64data, room_id);
        }
      }
    }
  }

  async function sendAjax(base64data, room_id, isAppend = true) {
    if (isAppend) {
      $("#my-chat-history").append(
        `<li class="right conversation-messages loading-voice-block">
          <div class="conversation-list">
            <div class="user-chat-content">
              <div class="ctext-wrap">
                <div class="ctext-wrap-content">
                  <div class="voice-block custom-voice-block">
                    <div class="media-button">
                      <i class="ri-play-fill"></i>
                    </div>
                    <img class="loading-image" src="/images/loading2.gif">
                    <span class="btn-shine">encrypting...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>`
      );
    }

    rollToBottom()

    await $.ajax({
      url: "/api/v1/send_voices/send_voice",
      type: "POST",
      async: true,
      data: { attach_file_url: base64data, file_type: 'voice', room_id: room_id, lifetime: $("#message-content .lifetime").val() },
      error: function(event) {
        setTimeout(function(){
          sendAjax(base64data, room_id, false)
        }, 2000);
      }
    });
  }

  const formatTimecode = seconds => {
    return new Date(seconds * 1000).toISOString().substr(14, 5)
  }

  var clearTimer = function() {
    clearInterval(recordTimer);
    $("#seconds").text("00")
    $("#minutes").text("00")
  }

  var startTimer = function() {
    var sec = 0;
    function pad ( val ) { return val > 9 ? val : "0" + val; }
    recordTimer = setInterval( function(){
      $("#seconds").html(pad(++sec%60));
      $("#minutes").html(pad(parseInt(sec/60,10)));
    }, 1000);
  }

  var startRecording = function() {
    const preview = document.getElementById("audio-playback");
    navigator.mediaDevices.getUserMedia({
      audio: {
        sampleRate: 48000,
        channelCount: 1,
        volume: 1.5,
        echoCancellation: true,
        noiseSuppression: true,
      }
    }).then(function (stream) {
      blobObject = {}
      audio_stream = stream;
      recorder = new MediaRecorder(stream);

      // when there is data, compile into object for preview src
      recorder.ondataavailable = function (e) {
        const url = URL.createObjectURL(e.data);
        preview.src = url;
        blobObject = e.data
      };
      recorder.start();

      timeout_status = setTimeout(function () {
        $(".voice-record-main .stop-btn").click();
        // notify
        $(".toast-body").text("Limited to 5 minutes");
        $(".toast-block").show();
        $('.toast').toast({ delay: 3000})
        $('.toast').toast("show")
        $(".toast-block").animate({width:'show'}, 3000);
      }, 300000);
    });
  }

  var stopRecording = function() {
    try {
      recorder.stop();
      audio_stream.getAudioTracks()[0].stop();
    }
    catch (exception_var) {
    }
  }
}).call(this);
