(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleSendSecretQuestion();
    handleAnswerSecretQuestion();
  }

  var handleSendSecretQuestion = function() {
    $('#send-secret-question-form').submit(function(e) {
      var datastring = $("form#send-secret-question-form").serialize();
      $.ajax({
        type: "POST",
        url: "/api/v1/friend/create_question",
        data: datastring,
        async: false,
        success: function(data) {
          $("#verify-friend-modal").modal("hide");
          // $(".answer-secret-quesion-block").remove();

          $("#complete-send-verify-friend-modal .answer").text(data.data.answer);
          $("#complete-send-verify-friend-modal .question").text(data.data.question);
          $("#send-secret-question-form input[name='dirrect_user_id']").val(data.data.dirrectUserId);

          //$(`.user-profile-desc`).before(`
          //<div class="answer-secret-quesion-block" style="background-color: #ffd165;padding: 16px;color: black;display: inline-block">
          //  <p>You already sent the verify request to your contact</p>
          //  <div style="float:right; font-weight: bold" data-bs-target="#complete-send-verify-friend-modal" data-bs-toggle="modal">VIEW</div>
          //</div>`)
          addNotVerifyContact(data.data)
          handleToast("Your friend request has been sent!");
          $("#send-secret-question-form")[0].reset();
        },
        error: function() {
        }
      });

      return false;
    });
  }

  var handleAnswerSecretQuestion = function() {
    $("#answer-secret-question-form").submit(function(e) {
      var datastring = $("form#answer-secret-question-form").serialize();
      $.ajax({
        type: "POST",
        url: "/api/v1/friend/answer_question",
        data: datastring,
        async: false,
        success: function(data) {
          $(".answer-secret-quesion-block").remove();
          $(`.request-user-id-${data.data.dirrectUserId}`).remove();
          addUserToContactList(data.data);
          friendRequestCounter();
          handleToast("congratulation! verification successful, your communications are now secured");
          $(".confirm-friend-modal").hide()
        }
      });
      return false;
    });
  }

  var friendRequestCounter = function() {
    const counter = $("#pills-requests .contact-list li").length;
    if (counter > 0) {
      $(".friend-request-counter").addClass("notify-counter");
      $(".friend-request-counter").text(counter);
      $("#pills-requests-tab").show();
    } else {
      $(".friend-request-counter").removeClass("notify-counter");
      $(".friend-request-counter").text("");
      $("#pills-requests-tab").hide();
    }
  }

  var addNotVerifyContact = function(data) {
    if ($(`.contact-user-id-${data.dirrectUserId}`).length === 0) {
      $("#pills-contacts .contact-list").prepend(`
        <li class="contact-user-id-${data.dirrectUserId}">
          <input type="hidden" class="custom-color-value-${data.dirrectUserId}" value="${data.dirrectUserColor}">
          <input type="hidden" class="custom-avatar-value-${data.dirrectUserId}" value="${data.dirrectUserAvatar}">
          <div class="d-flex align-items-center">
            <div class="col-11 user-item user-profile-show" user_id="${data.dirrectUserId}">
              <img class="avatar-img custom-color-${data.dirrectUserId}" style="border-color: ${data.dirrectUserColor}" src="${data.dirrectUserAvatar}">
              <div class="room-name custom-fullname-${data.dirrectUserId}" >${ htmlEncode(data.dirrectUserGid) }</div>
              <div class="inline-block">
                <div class="verify-status-block">Not Verified</div>
                ${ data.isShowAppMember ? '<div class="app-member-btn">App Member</div>' : '' }
                ${ data.isShowPhoneMember ? '<div class="app-member-btn">OS Member</div>' : '' }
              </div>
            </div>

            <div class="dropdown col-1">
              <a href="#" class="text-muted dropdown-toggle pull-right" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="ri-more-2-fill"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <a class="dropdown-item user-profile-show" user_id="${data.dirrectUserId}">View profile <i class="ri-profile-line float-end text-muted"></i></a>
                <a class="dropdown-item block-friend-action" user_id="${data.dirrectUserId}">Block <i class="ri-forbid-line float-end text-muted"></i></a>
                <a class="dropdown-item remove-friend-action" user_id="${data.dirrectUserId}">Remove <i class="ri-delete-bin-line float-end text-muted"></i></a>
              </div>
            </div>
          </div>
        </li>
      `)
    }
  }

  var addUserToContactList = function(data) {
    if ($(`.contact-user-id-${data.dirrectUserId}`).length === 0) {
      $("#pills-contacts .contact-list").prepend(`
        <li class="contact-user-id-${data.dirrectUserId}">
          <input type="hidden" class="custom-color-value-${data.dirrectUserId}" value="${data.dirrectUserColor}">
          <input type="hidden" class="custom-avatar-value-${data.dirrectUserId}" value="${data.dirrectUserAvatar}">
          <div class="d-flex align-items-center">
            <div class="col-11 user-item" onclick="startConversationFromContact(${data.dirrectUserId}, '${ data.dirrectUserName }')")>
              <img class="avatar-img custom-color-${data.dirrectUserId}" style="border-color: ${data.dirrectUserColor}" src="${data.dirrectUserAvatar}">
              <div class="room-name custom-fullname-${data.dirrectUserId}">${ htmlEncode(data.dirrectUserName) }</div>
              <div class="inline-block">
                ${ data.isShowAppMember ? '<div class="app-member-btn">App Member</div>' : '' }
                ${ data.isShowPhoneMember ? '<div class="app-member-btn">OS Member</div>' : '' }
              </div>
            </div>

            <div class="dropdown col-1">
              <a href="#" class="text-muted dropdown-toggle pull-right" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="ri-more-2-fill"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <a class="dropdown-item user-profile-show" user_id="${data.dirrectUserId}">View profile <i class="ri-profile-line float-end text-muted"></i></a>
                <a class="dropdown-item block-friend-action" user_id="${data.dirrectUserId}">Block <i class="ri-forbid-line float-end text-muted"></i></a>
                <a class="dropdown-item remove-friend-action" user_id="${data.dirrectUserId}">Remove <i class="ri-delete-bin-line float-end text-muted"></i></a>
              </div>
            </div>
          </div>
        </li>
      `)
    }
  }

  var handleToast = function(content) {
    $(".toast-body").text(content)
    $(".toast-block").show();
    $('.toast').toast({ delay: 4000})
    $('.toast').toast("show")
    $(".toast-block").animate({width:'show'}, 4000);
  }
}).call(this);
