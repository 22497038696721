import { replyMessageContent, htmlEncode } from './share_module'

(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    const currentUserId = parseInt(sessionStorage.getItem('current_user_id'))
    handleSubmitMessageVault();
    handleSubmitMessageRemote(currentUserId);
  }

  var handleSubmitMessageVault = function() {
    $('#attachment-section').submit(function(e) {
      var attachFiles = $("#attach-file-ids").val();

      if (attachFiles) {
        var datastring = $("form#attachment-section").serialize();
        sendVaultData(datastring);
        $("#attachment-section .enter-messsage-content").val("")
      }

      holdClick();
      $("#attachment-chat .attachment-chat-remove").click();
      return false;
    });
  }

  async function sendVaultData(datastring, isAppend = true) {
    // $(".preload").show();
    if (isAppend) {
      $("#my-chat-history").append(
        `<li class="right conversation-messages loading-text-block">
          <div class="conversation-list">
            <div class="user-chat-content">
              <div class="ctext-wrap">
                <div class="ctext-wrap-content">
                  <div class="voice-block custom-text-block">
                    <span class="btn-shine">encrypting...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>`
      );
    }
    rollToBottom()

    await $.ajax({
      type: "POST",
      url: "/conversations/reply",
      data: datastring,
      async: true,
      error: function(event) {
        setTimeout(function(){
          sendVaultData(datastring, false)
        }, 2000);
      }
    });
  };

  var handleSubmitMessageRemote = function(currentUserId) {
    $('#message-content').submit(function(e) {
      const messageContentData = $(".enter-messsage-content").val().trim()
      if (messageContentData.length === 0 && $("#forward-chat-content").length === 0) {
        return false;
      }

     // Submit form forward
      const forward_form = $("form#forward-chat-content")

      if (forward_form.length > 0) {
        var datastring = forward_form.serialize();
        $.ajax({
          type: "POST",
          url: "/conversations/forward",
          data: datastring,
          async: false,
          success: function(data) {

          }
        })

        $('.forward-section').remove();
      }

      const myAliasName = $(".my-alias-name").text();
      const isBroadCastGroup = $(".edit-broadcast-btn").length > 0
      sendReplyData(messageContentData, myAliasName, currentUserId, isBroadCastGroup);

      $('#chat-send').hide()
      $(".conversation-tool-button").show()

      // Reset reply section
      $('.chat-conversation').removeClass('reply-section-open');
      $('.reply-section').hide();
      $('.reply-section p').html('');
      $('#reply_id').val('');
      holdClick();
      $(".enter-messsage-content").focus();
      $(".enter-messsage-content").css("height", 39);

      return false;
    });
  }

  async function sendReplyData(messageContentData, myAliasName, currentUserId, isBroadCastGroup, isAppend = true) {
    const messageToken = (Math.random() + 0).toString(36).substring(2);
    let replyMessage = "";
    $(".enter-messsage-content").val("")
    const replyMessageId = $("#message-content #reply_id").val()

    if (replyMessageId.length > 0) {
      const messageType = $(`#message-${ replyMessageId }`).attr("message-type")
      const ownerName = htmlEncode($(".reply-section .reply-author-name").text())
      let content = ""
      var fileType

      switch (messageType) {
      case 'text':
        content = $(`#message-${ replyMessageId } .message-content`).text()
        break
      case 'file':
        content = $(`#message-${ replyMessageId } .each-file .file-detail p`).map( function() {return $(this).text()}).get().join(", ")
        break
      case 'csv':
        content = $(`#message-${ replyMessageId } .each-secured-book .file-detail p`).map( function() {return $(this).text()}).get().join(", ")
        break
      case 'image':
        fileType = $(`#message-${ replyMessageId } ul.message-img li:first img`).length > 0 ? 'image' : 'video'
        if (fileType == 'video') {
          content = $(`#message-${ replyMessageId } ul.message-img li:first video`).attr("src")
        } else {
          content = $(`#message-${ replyMessageId } ul.message-img li:first img`).attr("src")
        }

        break
      }
      content = htmlEncode(content)
      replyMessage = replyMessageContent(replyMessageId.length > 0, messageType, replyMessageId, ownerName, content, fileType)
    }

    if (messageContentData.length > 0) {
      if (isAppend) {
        $("#my-chat-history").append(
          `<li data-long-press-delay="500" class="right conversation-messages message-token-${messageToken}">
            <div class="conversation-list">
              <div class="user-chat-content">
                <div class="ctext-wrap">
                  <div class="ctext-wrap-content">
                    ${ replyMessage }
                    <p class="mb-0 message-content">${ htmlEncode(messageContentData) }</p>
                  </div>
                </div>
                <div class="chat-time mb-0">
                  <span class="align-middle send-status"><i class="ri-time-line"></i></span>
                </div>
              </div>
            </div>
          </li>`
        );
        rollToBottom()
      }

      var datastring = $("form#message-content").serialize();
      await $.ajax({
        type: "POST",
        url: "/conversations/reply",
        data: datastring + `&message=${encodeURIComponent(messageContentData)}&message_token=${messageToken}`,
        async: true,
        success: function(data) {

        },
        error: function(event) {
          $(`.message-token-${messageToken}`).remove()
          setTimeout(function(){
            sendReplyData(messageContentData, myAliasName, currentUserId, isBroadCastGroup, false)
          }, 2000);
        }
      });
    }
  }

}).call(this);
