(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleEnableSelectMessage();
    handleCloseSelectMessage();
  }

  var handleCloseSelectMessage = function() {
    $('.chat-checkbox-topbar-remove').on('click', function() {
      $("input[checked='checked']").click();
      $(".chat-conversation .round").removeClass("open")
      $('.chat-topnav').show();
      $('.chat-checkbox-topbar').hide();

      if ($('.reply-content').html().length > 0) {
        $('.reply-section').show();
        $('.chat-conversation').addClass('reply-section-open');
        rollToBottom();
      }

      if ($('.forward-content').html()?.length > 0) {
        $('.forward-section').show();
        $('.chat-conversation').addClass('forward-section-open');
        rollToBottom();
      }
    })
  }

  var handleEnableSelectMessage = function() {
    $("#user-chat-show").delegate(".edit-conversation-btn", "click", function() {
      $(".chat-conversation .round").addClass("open")
      $('.chat-topnav').hide();
      $('.chat-checkbox-topbar').show();
      $('.chat-checkbox-topbar').find('.message-selected-number').html("");
    })
  }

  holdClick = function() {
    $('#my-chat-history li.conversation-messages').removeClass('delete-message');
    $('.chat-topnav').show();
    $('.chat-checkbox-topbar').hide();
    $('#message-content').show();
    $('.checkbox-bottombar').hide();
    $(".chat-message-delete").hide();
    $(".chat-message-copy-clipboard").hide();

    $('.arr_message_id').val('');
    delete_message_id = [];
    forwardUserNames = [];
    pollIds = [];

    $(".chat-conversation .round").removeClass("open")
    $("#my-chat-history li.conversation-messages").removeClass("clicked")
    $("input[checked='checked']").removeAttr("checked")
  }

  handleLongPress = function(elements) {
    elements.on("long-press", function() {
      elements.find(".pd_btn").remove()
      $(".chat-conversation .round").addClass("open")
      $(this).click();
    })
  }

  handleSelectMessage = function(elements) {
    elements.on("click", function() {
      if ($(".chat-conversation .round").hasClass("open")) {
        if ($(this).attr('id')) {
          const messageId = parseInt($(this).attr('id').substring(8));
          if ($(this).attr('message-type') == 'poll') {
            if (pollIds.includes(messageId)) {
              const index = pollIds.findIndex(dt => dt === messageId)
              pollIds.splice(index, 1)
            } else {
              pollIds.push(messageId)
            }
          }

          if ($(this).find('.message-check').attr("checked") === "checked") {
            $(this).find('.message-check').attr("checked", false)
            $(this).removeClass("clicked");

            let pos = delete_message_id.findIndex(dt => dt === messageId)
            delete_message_id.splice(pos, 1);
          } else {
            $(this).addClass("clicked");
            $(this).find('.message-check').attr("checked", true);
            delete_message_id.push(messageId);
          }

          let countChoose = delete_message_id.length;
          let pollCount = pollIds.length;
          handleTopbar(countChoose);
          handleReply(countChoose);
          handleBottomBar(countChoose)
          handleForward(countChoose, pollCount)
          handleShowOnRemoveMessageModel(countChoose);
          handleShowAlsoRemoveMessageModel()
        }
      }
    })
  }

  var handleShowAlsoRemoveMessageModel = function() {
    const otherAuthors = delete_message_id.flatMap((x) => `#message-${x}.left`).join(",");
    const otherAuthorCount = $(otherAuthors).length
    const roomId = $('#my-chat-history').attr('room_id')
    const roomData = roomList.find(element => element.id === parseInt(roomId))
    const current_user_id = parseInt(sessionStorage.getItem('current_user_id'));

    if (roomData && roomData.roomType !== 'broadcast') {
      if (current_user_id === roomData.adminId || otherAuthorCount === 0 || roomData.roomType === 'dirrect') {
        $("#confirm-remove-message-content .also-delete-block").html(`<div class="mb-3">
          <label>
            <input type="checkbox" class="form-check-input" name="with_participant" value="1">
            &nbsp;Also delete <span class='content-remove-message-modal'></span> for ${ roomData.roomType === 'dirrect' ? htmlEncode(roomData.roomName) : "your group's participants" }
          </label>
        </div>`)
      } else {
        $("#confirm-remove-message-content .also-delete-block").html('')
      }
    } else {
      $("#confirm-remove-message-content .also-delete-block").html('')
    }
  }

  var handleShowOnRemoveMessageModel = function(countChoose) {
    if (countChoose > 1) {
      $("#confirm-remove-message-content .confirm-text").text("Are you sure you want to delete these messages")
    } else {
      $("#confirm-remove-message-content .confirm-text").text("Are you sure you want to delete this message")
    }
  }

  var handleTopbar = function(countChoose) {
    if (countChoose === 0) {
      $('.chat-checkbox-topbar').hide();
      $('.chat-topnav').show();
      $(".chat-conversation .round").removeClass("open")

      $(".chat-message-delete").hide();
      $(".chat-message-copy-clipboard").hide();
    } else {
      $('.chat-checkbox-topbar').show();
      $('.chat-topnav').hide();
      $('.chat-checkbox-topbar').find('.message-selected-number').html(countChoose);
      $(".chat-message-delete").show();
      $(".chat-message-copy-clipboard").show();
    }
  }

  var handleBottomBar = function(countChoose) {
    if (countChoose === 0) {
      $('#message-content').show();
      $('.checkbox-bottombar').hide();
    } else {
      $('#message-content').hide();
      $('.checkbox-bottombar').show();
    }
  }

  var handleForward = function(countChoose, pollCount) {
    if (countChoose === 0 || pollCount > 0) {
      $('.checkbox-forward').hide();
    } else {
      $('.checkbox-forward').show();
    }
  }

  var handleReply = function(countChoose) {
    if (countChoose == 1 && currentRoomType != "broadcast") {
      $('.checkbox-reply').children().show();
    } else {
      $('.checkbox-reply').children().hide();
    }
  }
}).call(this);
