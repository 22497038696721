
(function() {
	$(document).on('turbolinks:load', function() {
		init();
	});

	var init = function() {
		handleEditPhoto()
		handleCloseEditPhotoModal()
	}

	var handleCloseEditPhotoModal = function() {
		$(".edit-photo-modal .close-btn").on("click", function() {
			$(".edit-photo-modal").hide()
		})
	}

	var handleEditPhoto = function() {
		const editor = appendDefaultEditor('.inline-editor', {
      stickers: [['Emoji', ['⭐️', '😊', '👍', '👎', '☀️', '🌤', '🌥']],
			],
    });
		$("body").delegate(".start-edit-btn", "click", function() {
			Fancybox.close()
			const photoSrc = $(this).attr("download")
			$(".edit-photo-modal").show()

			editor.editImage(photoSrc).then((imageWriterResult) => {
				uploadData(imageWriterResult.dest)
      });

		})
	}

	async function uploadData(data) {
    let loading = true
    var result;

		var fd = new FormData();
    fd.append('file_type', 'image');
    fd.append('attach_file_url', data);

    await $.ajax({
      url: "/api/v1/vaults/upload_file",
      type: "POST",
      data: fd,
			processData: false,
      contentType: false,
			async: true,
      success: function(data) {
        result = data.data;
        loading = false
      }
    });

    if (!loading) {
      addPhotoBlock(result)
      showToast("Save to gallery completed!")
    }
  }

	var addPhotoBlock = function(result) {
    var galleryContent = `<div class='gallery-column photo-block'>
      <input type='checkbox' class='gallery-checkbox' id='gallery-checkbox-${result.id}' />
      <label for='gallery-checkbox-${result.id}' class='gallery-label'></label>
      <a class="fancybox-all" data-fancybox="gallery" data-caption="${result.createdAt}" data-src="${result.rawImage}">
        <img loading="lazy" src="${result.thumb}">
      </a>
    </div>`;
    $('#attachment-chat .take-photo-block').after(galleryContent);

    var vaultGalleryContent = `<div class='gallery-column photo-block'>
      <input type="checkbox" class='gallery-checkbox' id="vault-checkbox-${result.id}" />
      <label for="vault-checkbox-${result.id}" class='gallery-label'></label>
      <a class="fancybox-all" data-fancybox="gallery" data-caption="${result.createdAt}" data-src="${result.rawImage}">
        <img loading="lazy" src="${result.thumb}">
      </a>
    </div>`;
    $('#pills-vaults .take-photo-block').after(vaultGalleryContent);
  }
}).call(this);
