(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleSendFriendRequest();
    handleGotoRequest();
    handleBackToContact();
    handleRemoveFriend();
    handleBlockFriend()
    processRemoveFriend();
    processBlockFriend();
    sortContact();
  }

  var sortContact = function() {
    var mylist = $('#pills-contacts .contact-list');
    var listitems = mylist.children('li').get();
    listitems.sort(function(a, b) {
      return $(a).find(".room-name").text().toUpperCase().localeCompare($(b).find(".room-name").text().toUpperCase());
    })
    $.each(listitems, function(idx, itm) { mylist.append(itm); });
  }

  var callRemoveFriend = function(userId) {
    $.ajax({
      url: `/api/v1/friend/remove_friend?dirrect_user_id=${userId}`,
      type: "GET",
      async: true,
    });
  }

  var callBlockFriend = function(userId) {
    $.ajax({
      url: `/api/v1/friend/block_friend?dirrect_user_id=${userId}`,
      type: "GET",
      async: true,
    });
  }

  var processRemoveFriend = function() {
    $(".remove-friend-btn").on("click", function() {
      const user_id = $(this).attr("user_id")
      $(`li.contact-user-id-${user_id}`).remove();
      $(`li.request-user-id-${user_id}`).remove();

      const room = roomList.find( (item) => item.dirrectUserId == user_id)
      if (room) {
        $(`#conversation-no-${room.id}`).remove();
      }
      $("#confirm-remove-friend-modal").modal("hide");
      $(".confirm-friend-modal").hide()
      callRemoveFriend(user_id);
    })
  }

  var processBlockFriend = function() {
    $(".block-friend-btn").on("click", function() {
      const user_id = $(this).attr("user_id")
      $(`li.contact-user-id-${user_id}`).remove();
      $(`li.request-user-id-${user_id}`).remove();

      const room = roomList.find( (item) => item.dirrectUserId == user_id)
      if (room) {
        $(`#conversation-no-${room.id}`).remove();
      }
      $("#confirm-block-friend-modal").modal("hide");
      callBlockFriend(user_id);
    })
  }

  var handleRemoveFriend = function() {
    $("#pills-contacts, #pills-requests").delegate(".remove-friend-action", "click", function() {
      const user_id = $(this).attr("user_id")
      $("#confirm-remove-friend-modal").modal("show");
      $("#confirm-remove-friend-modal .remove-friend-btn").attr("user_id", user_id)
      $("#confirm-remove-friend-modal .delete-user-name").text($(`.custom-fullname-${user_id}:first`).text())
    })
    $(".confirm-friend-modal .remove-friend-action").on("click", function() {
      const user_id = $(this).attr("user_id")
      $("#confirm-remove-friend-modal").modal("show");
      $("#confirm-remove-friend-modal .remove-friend-btn").attr("user_id", user_id)
    })
  }

  var handleBlockFriend = function() {
    $("#pills-contacts, #pills-requests").delegate(".block-friend-action", "click", function() {
      const user_id = $(this).attr("user_id")
      $("#confirm-block-friend-modal").modal("show");
      $("#confirm-block-friend-modal .block-friend-btn").attr("user_id", user_id)
      $("#confirm-block-friend-modal .block-user-name").text($(`.custom-fullname-${user_id}:first`).text())
    })
  }

  var handleBackToContact = function() {
    $(".back-to-contact-btn").on("click", function() {
      activaTab("pills-contacts", "pills-contacts");
    })
  }

  var handleGotoRequest = function() {
    $("#pills-requests-tab").on("click", function() {
      activaTab("pills-requests", "pills-requests");
    })
  }

  var handleSendFriendRequest = function() {
    $("#addContact-exampleModal").delegate("#send-friend-request", "click", function() {
      const dirrectUserId = $(this).attr("user_id");
      $("#addContact-exampleModal").modal("hide");
      $("#send-secret-question-form input[name='dirrect_user_id']").val(dirrectUserId);
      $("#verify-friend-modal").modal("show");
    });
  }

  function getRoomDataByUserId(userId) {
    let result = {};
    $.ajax({
      url: `api/v1/conversations/listing?dirrect_user_id=${userId}`,
      type: "GET",
      async: false,
      success: function(data) {
        roomList.push(data.data);
        result =  data.data;
      }
    });
    return result;
  }
}).call(this);
