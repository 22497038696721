currentRoomType = "";
clickConvCount = 0;

(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleButtonChatSend();
    handleDeleteMessage();
    startConversation();
    autoResizeMessageContentEnter();
    curentTimezone = readCookie("user_time_zone");
    handleFocusEnterChat();
  }

  var handleFocusEnterChat = function() {
    $(".enter-messsage-content").on("click", function() {
      setTimeout(function(){
        rollToBottom();
      }, 200);
    });
  }

  var handleButtonChatSend = function() {
    $(".enter-messsage-content").on("change keyup", function() {
      if ($(".enter-messsage-content").val().length == 0 && $("#forward-chat-content").length === 0) {
        $('#chat-send').hide()
        $(".conversation-tool-button").show()
      } else {
        $('#chat-send').show()
        $(".conversation-tool-button").hide()
      }
    });

    $(".enter-messsage-content").on("paste", function() {
      $('#chat-send').show()
      $(".conversation-tool-button").hide()
    });
  }

  var handleDeleteMessage = function() {
     $('#delete-message-btn').on('click', function(e) {
      $('.arr_message_id').val(delete_message_id);
      $('#remove-message-modal').modal('toggle');
    });
  }

  var autoResizeMessageContentEnter = function() {
    $('.enter-messsage-content').on('input', function () {
      this.style.height = 'auto';
      this.style.height = (this.scrollHeight >= 200 ? 200 : this.scrollHeight) + 'px';
    });
  }

  var startConversation = function() {
    $(".user-chat-remove").click(function() {
      const roomId = $('#my-chat-history').attr('room_id');
      $(".user-chat").removeClass("user-chat-show");
      $('#my-chat-history').attr('room_id', "")
      // if forward content in open
      $('.forward-section').remove();
      // if reply content in open
      $('.close-reply-section').click()
      $(".enter-messsage-content").val("");
    });
  }

  handleClickConversation = function(e) {
    $('.enter-messsage-content').css("height","39")
    clickConvCount += 1;
    if (clickConvCount == 1) {
      resetSearchBox();
      window.history.pushState("", "", "/conversations");
      const current_user_id = parseInt(sessionStorage.getItem('current_user_id'));
      const roomId = parseInt($(e).attr('id').substring(16));
      const roomName = $(e).find('.room-name').text()
      startConversationMain(roomId, roomName, current_user_id, null);
      // handle for create poll
      handleCreatePoll(roomId);
      handleTimePicker();
      handleScrollAndLoadMessage(roomId, current_user_id);
    }
  }

  handleAddMoreData = function(roomData, current_user_id, isScrollLoad = true, scrollToBottom = false) {
    let pollArray = []
    const ulContent = listingConversation(roomData, current_user_id, pollArray);

    if (isScrollLoad) {
      const firstId = roomData.conversations[roomData.conversations.length - 1].id
      $("#my-chat-history").prepend(ulContent);
      if (scrollToBottom) {
        rollToBottomFast();
      } else {
        $("#user-chat-show .simplebar-content-wrapper").scrollTop($(`#message-${firstId}`).offset().top)
      }
    } else {
      $(`#my-chat-history[room_id="${roomData.id}"]`).html(ulContent);
      rollToBottomFast();
    }

    const elementList = roomData.conversations.map((item) => `#message-${item.id}`).join(", ");
    handleSelectMessage($(elementList));
    handleLongPress($(elementList));

    if ($(".conversation-messages .round").hasClass("open")) {
      $(".conversation-messages .round").addClass("open");
    } else {
      $(".conversation-messages .round").removeClass("open");
    }
    handleVoice(roomData);

    pollArray.forEach(function(message) {
      $(`.simple-timer-${message.id}`).syotimer({
        date: new Date(message.expirationTime),
      });
    })
  }

  var handleScrollAndLoadMessage = function(roomId, current_user_id) {
    var roomData;
    let totalData = 1;

    var scrollHandler = function() {
      if (this.scrollTop === 0 && totalData !== 0 && $("#my-chat-history").height() > $(".chat-conversation").height()) {
        const lastId = $('#my-chat-history').children().eq(1).attr("id")?.substring(8);
        if (!lastId) return
        // Create a promise for the ajax call
        var loadingPromise = new Promise(function(resolve, reject) {
          $(".progress-bar").show();
          $.ajax({
            url: `/api/v1/conversations/listing?room_id=${roomId}&last_id=${lastId}`,
            type: "GET",
            async: true,
            success: function(data) {
              roomData = data.data;
              totalData = roomData.conversations.length;
              resolve(roomData);
            },
            error: function(xhr, status, error) {
              reject(error);
            }
          });
        });

        loadingPromise.then(function(roomData) {
          if (totalData > 0) {
            handleAddMoreData(roomData, current_user_id)
          } else {
            $("#my-chat-history").prepend('<li class="date-break-line"><p class="date-content">No previous messages...</p></li>');
          }
        }).catch(function(error) {
          console.error("Error loading data:", error);
        }).finally(function() {
          setTimeout(function() {$(".progress-bar").hide()}, 300);
        });
      }
    }

    $("#user-chat-show .simplebar-content-wrapper").scroll(scrollHandler);

    $(".user-chat-remove").click(function() {
      clickConvCount = 0;
      $("#user-chat-show .simplebar-content-wrapper").off("scroll", scrollHandler);
    });
    $(".forward-group-list li").click(function() {
      $("#user-chat-show .simplebar-content-wrapper").off("scroll", scrollHandler);
    });
  }

  var handleTimePicker = function() {
    $("#duration-group").empty();
    $("#duration-group").append(`<input type="hidden" name="duration" value="86400"/>
      <div>
        <span id="duration-label" style="font-size: 14px;font-weight: 300;">Expires in 1 day</span>
      </div>`);

    $('input[name=duration]').durationpicker()
    .on("change", function(){
        const days = $(this).attr("days");
        const hours = $(this).attr("hours");
        const mins = $(this).attr("mins");

        $('#duration-label').text("Expires in " + translate(days, hours, mins));
    });
  }

  var translate = function(days, hours, minutes) {
    var units = [];
    if (days > 0 ) {
      units.push(`${days} days`);
    }
    if (hours > 0 ) {
      units.push(`${hours} hours`);
    }
    if (minutes > 0 ) {
      units.push(`${minutes} minutes`);
    }

    var last = "";
    if ( units.length > 1 ) {
      last = " " + "and" + " " + units.pop();
    }
    return units.join( ", " ) + last;
  }

  var handleCreatePoll = function(roomId) {
    $("#create-polls-modal input[name='room_id']").val(roomId);
    $("#create-polls-modal .create-poll-form")[0].reset();
    $("#create-polls-modal .option-item").remove();
    $("#create-polls-modal .add-option-btn").show();
    // set datetime for poll
    var now = new Date();
    var dateTimeCurrent = new Date(now.getTime()-now.getTimezoneOffset()*60000).toISOString().substring(0,16);
    $("#expiration-input").val(dateTimeCurrent);
  }

  startConversationMain = function(roomId, roomName, current_user_id, dirrectUserId) {
    var roomData;
    if (dirrectUserId) {
      roomData = roomList.find(element => element.dirrectUserId === dirrectUserId)
    } else {
      roomData = roomList.find(element => element.id === roomId)
    }

    if (roomData === undefined) {
      if (dirrectUserId) {
        roomData = getRoomDataByUserId(dirrectUserId);
      } else {
        roomData = getRoomData(roomId);
      }
    }

    if (roomId === null) {
      roomId = roomData.id;
    }

    handleMention(roomData)

    // set room type use for edit conversations
    currentRoomType = roomData.roomType;

    $('.note-view').attr('room_id', roomId);

    // remove forward model when click choose group to forward
    $('.forward-chat').removeClass('forward-chat-show');

    $("#message-content input[name='room_id']").val(roomId)
    $("#attachment-section input[name='room_id']").val(roomId)
    $('#my-chat-history').attr('room_id', roomId);
    $(".chat-topnav .room-name").addClass(`custom-fullname-${roomData.dirrectUserId}`);

    // allow click to show profile
    if (roomData.roomType == "dirrect") {
      $("#user-chat-show").attr("user_id", roomData.dirrectUserId)
      $(".chat-topnav .room-name").addClass("user-profile-show");
    } else {
      $(".chat-topnav .room-name").removeClass("user-profile-show");
    }
    requestToUpdateReadStatus(roomId)
    // must be check read before reloadStatistic
    checkRead(roomId)
    enterConversation(roomId, roomName, roomData, current_user_id);
    processForward(roomId)
    openModal();
    handleSelectMessage($("ul#my-chat-history li.conversation-messages"));
    holdClick();
    handleLongPress($("#my-chat-history li.conversation-messages"));

    if (['dirrect', 'broadcast'].includes(roomData.roomType)) {
      $(".poll-toolbar-btn").hide();
      $(".poll-toolbar-btn").removeClass("conversation-tool-button")
    } else {
      $(".poll-toolbar-btn").addClass("conversation-tool-button")
      $(".poll-toolbar-btn").show();
    }
    setTimeout(function() { rollToBottomFast(); }, 100);

    const unreadElement = $("#unread-message-element")
    if (unreadElement.length > 0) {
      setTimeout(function() {
        unreadElement.css({ backgroundColor: '#00a0b052' });
        document.querySelector("#unread-message-element").scrollIntoView({
          behavior: 'smooth'
        });
        setTimeout(function() {
          unreadElement.css({ backgroundColor: 'transparent' });
        }, 1500);
      }, 300)
    }
  }

  function getRoomDataByUserId(userId) {
    let result = {};
    $.ajax({
      url: `/api/v1/conversations/listing?dirrect_user_id=${userId}`,
      type: "GET",
      async: false,
      success: function(data) {
        roomList.push(data.data);
        result =  data.data;
      }
    });
    return result;
  }

  var enterConversation = function(roomId, roomName, roomData, current_user_id) {
    $(".notify-broadcast-message").remove();
    if (roomData.roomType === 'broadcast' && roomData.conversations.length == 0) {
      $(".chat-conversation .simplebar-content").prepend(`
        <li class="notify-broadcast-message" style="list-style: none;">
          <div class="conversation-list">
            <div class="user-chat-content">
              <div class="ctext-wrap">
                <div class="ctext-wrap-content">
                  <i class="ri-lock-password-fill"></i>
                  Messages to this broadcast list are now secured with end-to-end encryption.
                </div>
              </div>
            </div>
          </div>
        </li>
      `)
    }
    if (roomData) {
      $(".conversation-wrapper").attr('id', `wrapper-conversation-${roomId}`);
      $(".conversation-wrapper .room-name").text(roomName);

      if (roomData.roomType === 'broadcast') {
        const after = roomData.memberCount > 1 ? 'Recipients' : 'Recipient'
        $(".member-count").text(`${roomData.memberCount} ${after}`);
        $(".conversation-wrapper a.room-name").removeAttr("href data-bs-toggle data-remote");
        $(".conversation-wrapper .room-name-block").addClass("edit-broadcast-btn")
      } else if (roomData.roomType === 'dirrect') {
        $(".member-count").text('');
        $(".conversation-wrapper a.room-name").removeAttr("href data-bs-toggle data-remote");
        $(".conversation-wrapper .room-name-block").removeClass("edit-broadcast-btn")
      } else {
        $(".member-count").text(`${roomData.memberCount} members`);
        $(".conversation-wrapper a.room-name").attr("href", `/groups/${roomId}/edit`)
        $(".conversation-wrapper a.room-name").attr("data-bs-toggle", "modal");
        $(".conversation-wrapper a.room-name").attr("data-remote", "true");
        $(".conversation-wrapper .room-name-block").removeClass("edit-broadcast-btn")
      }

      let pollArray = [];
      const ulContent = listingConversation(roomData, current_user_id, pollArray);
      $("#my-chat-history").html(ulContent);

      pollArray.forEach(function(message) {
        $(`.simple-timer-${message.id}`).syotimer({
          date: new Date(message.expirationTime),
        });
      })

      const initContent = renderInitMessage(roomData);
      $("#my-chat-init-message").html(initContent);

      handleVoice(roomData);
      displayLifeTime(roomData);
      confirmRemoveMessageComponent(roomData, roomName, roomId, current_user_id);
      confirmDeleteMessageComponent(roomData, roomName, roomId, current_user_id);
      confirmClearAllComponent(roomData, roomName, roomId, current_user_id);
      reloadStatistic(roomData.roomType);
      renderRoomMenuActions(roomData, current_user_id, roomName);
    }
  }

  handleVoice = function(roomData) {
    const conversations = roomData.conversations.filter((message) => message.messageVoice)

    conversations.forEach(function(message) {
      const wavesurfer = WaveSurfer.create({
        container: `#voice-message-${message.id}`,
        waveColor: 'white',
        progressColor: '#bdc0c2'
      });
      wavesurfer.setHeight(36.5)
      wavesurfer.load(message.messageVoice);

      $(`#message-${message.id} .ri-play-fill`).on("click", function() {
        wavesurfer.play();
      })

      $(`#message-${message.id} .ri-pause-fill`).on("click", function() {
        wavesurfer.pause();
      })

      wavesurfer.on("pause", function() {
        $(`#message-${message.id} .ri-play-fill`).show();
        $(`#message-${message.id} .ri-pause-fill`).hide();
      });

      wavesurfer.on("play", function() {
        $(`#message-${message.id} .ri-play-fill`).hide();
        $(`#message-${message.id} .ri-pause-fill`).show();
      });
      wavesurfer.on("ready", () => {
        $(`.voice-duration-${message.id}`).text(formatTimecode(wavesurfer.getDuration()));
      });

      wavesurfer.on('audioprocess', function() {
        if (wavesurfer.isPlaying()) {
          var totalTime = wavesurfer.getDuration(),
          currentTime = wavesurfer.getCurrentTime(),
          remainingTime = totalTime - currentTime;
          $(`.voice-duration-${message.id}`).text(formatTimecode(remainingTime));
          if (remainingTime == 0) {
            $(`.voice-duration-${message.id}`).text(formatTimecode(totalTime));
          }
        }
      });
      wavesurfer.on('finish', function() {
        $(`.voice-duration-${message.id}`).text(formatTimecode(wavesurfer.getDuration()));
      });

      $(".user-chat-remove").on("click", function() {
        wavesurfer.destroy();
      })
    });
  }

  const formatTimecode = seconds => {
    return new Date(seconds * 1000).toISOString().substr(14, 5)
  }

  var processForward = function(roomId) {
    renderForwardContent(roomId);
    if ($("#forward-chat-content").length > 0) {
      $('#chat-send').show()
      $(".conversation-tool-button").hide()
    } else {
      $('#chat-send').hide()
      $(".conversation-tool-button").show()
    }
  }

  var renderForwardContent = function(roomId) {
    if (forwardUserNames.length > 0 && delete_message_id.length > 0) {
      var index = forwardUserNames.indexOf('');
      if (index !== -1) {
          forwardUserNames[index] = 'You';
      }
      const forwardUserNamesString = forwardUserNames.join(", ")
      $("#forwarded-content-wrapper").html(forwardedContent(roomId, forwardUserNamesString));
    }
  }

  var forwardedContent = function(roomId, forwardUserNamesString) {
    const lifetime = $("#message-content .lifetime").val();
    return `<div class='forward-section border-bottom'>
      <form id="forward-chat-content">
        <input type="hidden" name="forward_message_ids" id="forward-message-ids" value="${delete_message_id}" />
        <input type="hidden" name="room_id" value="${roomId}" />
        <input type="hidden" name="lifetime" class='lifetime' value="${ lifetime }" />
      </form>
      <div class='row px-3 pb-2'>
        <span id="main-app-color" class='col-1 text-primary'>
          <i class="ri-arrow-right-fill"></i>
        </span>
        <div class="mb-0 px-3 col-10">
          <p class='forward-content'>
            Forward ${delete_message_id.length} ${ delete_message_id.length > 1 ? 'messages' : 'message'}
          </p>
          <p id="main-app-color" class='text-primary forward-author-name'>From ${ htmlEncode(forwardUserNamesString) }</p>
        </div>
        <span class='close-forward-section col-1'>
          <i class="ri-close-line"></i>
        </span>
      </div>
    </div>`
  }

  function getRoomData(roomId) {
    let result = {};
    $.ajax({
      url: `/api/v1/conversations/listing?room_id=${roomId}`,
      type: "GET",
      async: false,
      success: function(data) {
        roomList.push(data.data);
        result =  data.data;
      }
    });
    return result;
  }

  var renderRoomMenuActions = function(roomData, current_user_id, roomName) {
    const roomMenuContentResult = roomMenuContent(roomData, current_user_id, roomName);
    $(".conversation-wrapper .more-room-actions").html(roomMenuContentResult);
  }

  var roomMenuContent = function(roomData, current_user_id, roomName) {
    const isPremiumUser = sessionStorage.getItem('is_premium_user');
    let data = `<span class="dropdown-item d-block d-lg-none start-search-message">Search <i class="ri-search-line float-end text-muted"></i></span>
      <a class="dropdown-item d-block d-lg-none edit-conversation-btn">Select messages <i class="ri-list-check float-end text-muted"></i></a>`;

    if (['dirrect', 'group_chat', 'zero_group'].includes(roomData.roomType)) {
      data += '<a class="dropdown-item d-block d-lg-none user-note-show" data-bs-toggle="modal" data-bs-target="#user-note-modal">Chalkboard <i class="ri-booklet-line float-end text-muted"></i></a>';
    }

    if (roomData.roomType === 'dirrect') {
      data += `<a class="dropdown-item d-block d-lg-none user-profile-show">View profile <i class="ri-profile-line float-end text-muted"></i></a>`
    } else if (roomData.roomType === 'broadcast') {
      data += `<a class="dropdown-item d-block d-lg-none edit-broadcast-btn">Edit broadcast <i class="ri-pencil-line float-end text-muted"></i></a>`
    } else {
      data += `<a class="dropdown-item" href="/groups/${roomData.id}/edit" data-remote="true" data-bs-toggle="modal" data-bs-target="#addgroup-exampleModal">
        ${ current_user_id === roomData.adminId ? 'Edit group' : 'View group' }
        <i class="ri-group-line float-end text-muted"></i>
      </a>`
    }

    data += `<a class="dropdown-item d-block d-lg-none" href="#" data-bs-toggle="modal" data-bs-target="#clear-history-modal">Clear history <i class="ri-chat-delete-fill float-end text-muted"></i></a>`

    if (roomData.roomType === 'dirrect') {
      data += `<a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete-history-modal">Delete <i class="ri-delete-bin-line float-end text-muted"></i></a>`
    } else if (roomData.roomType === 'broadcast') {
      data+= `<a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete-history-modal" style="width: 220px">Destroy broadcast <i class="ri-delete-bin-line float-end text-muted"></i></a>`
    } else {
      data+= `<a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#delete-history-modal" style="width: 220px">${ current_user_id === roomData.adminId ? 'Destroy group' : 'Leave group' } <i class="ri-delete-bin-line float-end text-muted"></i></a>`
    }
    data += `<span class="dropdown-item" id="show-message-template">Message template <i class="ri-file-copy-2-line float-end text-muted"></i></span>`

    if (isPremiumUser === "true") {
      data += `<span class="dropdown-item" id="start-screenshot-conversation" room_id="${roomData.id}" room_name="${htmlEncode(roomName)}">Export chat log <i class="ri-save-line float-end text-muted"></i></span>`
    }
    return data;
  }

  var requestToUpdateReadStatus = function(roomId) {
    if ($(`.chat-list #conversation-no-${roomId} .rounded-pill`).text() !== "") {
      $.ajax({
        url: `api/v1/conversations/mark_read?room_id=${roomId}`,
        type: "GET",
        async: true,
      });
    }
  }

  var reloadStatistic = function(roomType) {
    if (roomType === 'dirrect') {
      var total = 0
      $(".chat-user-list .rounded-pill").each(function(e) {
        if ($(this).text()) {
          total += parseInt($(this).text());
        }
      })
      if (total > 0) {
        $("#dirrect-message-counter").text(total)
      } else {
        $("#dirrect-message-counter").removeClass("notify-counter")
        $("#dirrect-message-counter").text("")
      }
    } else if (roomType === 'group_chat') {
      var totalOnGroup = 0
      $(".chat-group-list .rounded-pill").each(function(e) {
        if ($(this).text()) {
          totalOnGroup += parseInt($(this).text());
        }
      })
      if (totalOnGroup > 0) {
        $("#group-message-counter").text(totalOnGroup)
      } else {
        $("#group-message-counter").removeClass("notify-counter")
        $("#group-message-counter").text("")
      }
    } else if (roomType === 'zero_group') {
      var totalOnGroup = 0
      $(".chat-zero-group-list .rounded-pill").each(function(e) {
        if ($(this).text()) {
          totalOnGroup += parseInt($(this).text());
        }
      })

      if (totalOnGroup > 0) {
        $("#zero-group-message-counter").text(totalOnGroup)
      } else {
        $("#zero-group-message-counter").removeClass("notify-counter")
        $("#zero-group-message-counter").text("")
      }
    }
  }

  var checkRead = function(roomId) {
    $(`.chat-list #conversation-no-${ roomId } .unread-message span`).text("")
    $(`.chat-list #conversation-no-${ roomId } .chat-user-message`).removeClass("chat-user-message-unread")
  }

  var confirmClearAllComponent = function(roomData, roomName, roomId, current_user_id) {
    $("#clear-history-modal input[name='room_id']").val(roomId);
    renderModalConfirmClearAll(roomData, roomName, current_user_id)
  }

  var confirmRemoveMessageComponent = function(roomData, roomName, roomId, current_user_id) {
    $("#remove-message-modal input[name='room_id']").val(roomId);
  }

  var confirmDeleteMessageComponent = function(roomData, roomName, roomId, current_user_id) {
    $("#delete-history-modal input[name='room_id']").val(roomId);
    let deleteLabel = "";
    switch (roomData.roomType) {
      case 'dirrect':
        deleteLabel = 'Delete History';
        break;
      case 'broadcast':
        deleteLabel = 'Delete Broadcast';
        break;
      default:
        deleteLabel = roomData.adminId === current_user_id ? 'Destroy Group' : 'Leave Group'
    }

    $("#delete-history-modal-label").text(deleteLabel);
    renderModalConfirmDeleteGroup(roomData, roomName, current_user_id);
  }

  var toolCalcTime = function(val) {
    var countDownDate = new Date(val).getTime();

    var now = new Date().getTime() - globalShiftTime;
    var distance = (countDownDate - now) / 1000;

    var days = Math.floor(distance / (3600*24));
    var hours = Math.floor(distance % (3600*24) / 3600);
    var minutes = Math.floor(distance % 3600 / 60);
    var time = days > 0 ? `${ days + 1 }d` : hours > 0 ? `${ hours + 1}h` : `${ minutes + 1}m`;

    return [time, distance];
  }

  var displayLifeTimeProcess = function(el, val, roomData) {
    let conversations = roomData.conversations;
    var displayCountDownLifeTime = el.find('.display-countdown-lifetime');
    var id = el.attr('id').split('message-')[1];

    var x = setInterval(function() {
      result = toolCalcTime(val)
      const time = result[0];
      const distance = result[1];
      displayCountDownLifeTime.html(time);

      if (distance < 0) {
        $(el).remove();
        let dt = conversations.findIndex(dt => dt.id == id)

        if (dt === -1) {
          clearInterval(x);
          return;
        }

        conversations.splice(dt, 1);

        var last_message_content = '';
        let last_message_index = conversations.length - 1
        if (last_message_index >= 0 ) {
          const last_message = conversations[last_message_index]
          if (last_message.poll) {
            last_message_content = "Poll card";
          } else if (last_message.messageImages) {
            last_message_content = "Photo";
          } else if (last_message.messageVoice) {
            last_message_content = "Voice message";
          } else if (last_message.messageFiles) {
            last_message_content = "File";
          } else {
            last_message_content = last_message.message
          }
        } else {
          last_message_content = 'no message'
        }
        $(`#conversation-no-${roomData.id} .chat-user-message`).text(last_message_content)

        clearInterval(x);
      }
    }, 1000);
  }

  var displayLifeTime = function(roomData) {
    $('#my-chat-history li.conversation-messages').each(function() {
      var val = $(this).find('.delete_date').val();
      // Check if message is have delete_date or not
      if (val != undefined) {
        displayLifeTimeProcess($(this), val, roomData);
      }
    });
  }

  var renderInitMessage = function(roomData) {
    if (roomData.conversations.length == 0) {
      return `<div class="template">
        <div class="wrapper">
          <p class="text-center">${ htmlEncode(roomData.initMessage) }</p>

          <div>Secret chat:</div>
          <div style="font-size: 14px;">
            <div class="d-flex align-items-center pt-2"><i class="ri-lock-password-fill"></i>&nbsp;${['dirrect', 'broadcast'].includes(roomData.roomType) ? 'User end-to-end encryption' : 'Advanced encryption standard'}</div>
            <div class="d-flex align-items-center pt-2"><i class="ri-lock-password-fill"></i>&nbsp;Leave no trace on our services</div>
            <div class="d-flex align-items-center pt-2"><i class="ri-lock-password-fill"></i>&nbsp;Have a self-detruct timer</div>
          </div>
        </div>
      </div>`;
    }
    return '';
  }

  listingConversation = function(roomData, current_user_id, pollArray) {
    let currentTime = new Date().getTime() - globalShiftTime
    const conversations = roomData.conversations;
    var data = "";
    let lastDateSent = "";
    const myReadStatus = roomData.myReadStatus
    const countUnread = myReadStatus ? myReadStatus.count : 0
    const firstElId = myReadStatus ? myReadStatus.firstEl : null

    var unreadMessageHtml = ''
    if (countUnread == 1) {
      unreadMessageHtml = `${countUnread} unread message`
    } else if (countUnread > 1) {
      unreadMessageHtml = `${countUnread} unread messages`
    }

    conversations.forEach(function(message) {
      const customName = roomData.roomType == 'zero_group' ? message.name : $(`#pills-contacts .custom-fullname-${message.userId}:first`).text();
      const timeDestroy = new Date(message.deleteDate).getTime();

      const messageCreatedAt = moment.utc(message.createdAt).tz(curentTimezone);
      const dateSent = messageCreatedAt.format("MMMM Do");
      const timeSent = messageCreatedAt.format("HH:mm");

      if (lastDateSent !== dateSent) {
        data += `<li class="date-break-line"><p class="date-content">${dateSent}</p></li>`;
        lastDateSent = dateSent
      }

      if (firstElId && message.id == firstElId) {
        data += `<li class="date-break-line" id="unread-message-element"><p class="date-content">${unreadMessageHtml}</p></li>`;
      }

      if (message.deleteDate === null || (timeDestroy > currentTime)) {
        if (message.poll) {
          data += pollContent(message, roomData, current_user_id, customName, timeSent)
          pollArray.push(
            { id: message.id, expirationTime: message.poll.expirationTime }
          );
        }
        else if (message.messageImages) {
          data += imageMessageContent(message, roomData, current_user_id, customName, timeSent);
        } else if (message.messageFiles) {
          data += fileMessageContent(message, roomData, current_user_id, customName, timeSent);
        } else if (message.messageVoice) {
          data += voiceMessageContent(message, roomData, current_user_id, customName, timeSent);
        } else if (message.messageType == 'system') {
          data += `<li class="date-break-line" id="message-${message.id}"><p class="date-content">${htmlEncode(message.message)}</p></li>`;
        } else {
          data += messageContent(message, roomData, current_user_id, customName, timeSent);
        }
      }
    })
    return data;
  }

  var openModal = function() {
    $("#user-chat-show").addClass("user-chat-show");
  }

  var forwardContent = function(authorName) {
    if (authorName) {
      return `<p class="mb-2 text-success border-bottom">
        Forwarded message
        <br />
        From <b>${htmlEncode(authorName)}</b>
      </p>`
    }
    return ''
  }

  var replyContent = function(replyMessageContent, replyMessageFullname, roomData, data) {
    replyMessageFullname = htmlEncode(replyMessageFullname)

    if (data.replyMessageType === 'voice') {
      return `<div class='border-bottom reply-section-content' reply-id="${data.replyMessageId}">
        <p class="text-success">${replyMessageFullname}</p>
        <p class="poll-icon-text"><i class="ri-mic-line chart-icon"></i> Voice message</p>
      </div>`
    } else if (data.replyMessageType === 'poll') {
      return `<div class='border-bottom reply-section-content' reply-id="${data.replyMessageId}">
        <p class="text-success">${replyMessageFullname}</p>
        <p class="poll-icon-text"><i class="ri-pie-chart-2-line chart-icon"></i> Poll</p>
      </div>`
    } else if (data.replyMessageType === 'image') {
      return `<div class='border-bottom reply-section-content reply-section-content-photo' reply-id="${data.replyMessageId}">
        <div class="image-block">
          ${ data.replyPhotoUrl[1] == 'video' ? `<video src="${data.replyPhotoUrl[0]}" /></video>` : `<image src="${data.replyPhotoUrl[0]}" />` }
        </div>
        <div class="right-block">
          <p class="text-success">${replyMessageFullname}</p>
          <p>Gallery</p>
        </div>
      </div>`
    } else if (data.replyMessageType === 'file') {
      return `<div class='border-bottom reply-section-content' reply-id="${data.replyMessageId}">
        <p class="text-success">${replyMessageFullname}</p>
        <p class="poll-icon-text"><i class="ri-file-line chart-icon"></i> ${ htmlEncode(data.replyFileNames) }</p>
      </div>`
    } else if (data.replyMessageType === 'csv') {
      return `<div class='border-bottom reply-section-content' reply-id="${data.replyMessageId}">
        <p class="text-success">${replyMessageFullname}</p>
        <p class="poll-icon-text"><i class="ri-file-excel-2-line chart-icon"></i> ${ htmlEncode(data.replyFileNames) }</p>
      </div>`
    } else if (replyMessageContent) {
      return `<div class='border-bottom reply-section-content' reply-id="${data.replyMessageId}">
        <p class="text-success">${replyMessageFullname}</p>
        <p>${fixMentionName(htmlEncode(replyMessageContent))}</p>
      </div>`
    }
    return ''
  }

  var deleteDateBlock = function(data) {
    if (data.deleteDate) {
      return `<span class="display-countdown-lifetime">${toolCalcTime(data.deleteDate)[0]}</span>
        <input type="hidden" name="delete_date" class="delete_date" value="${data.deleteDate}"`;
    }
    return '';
  }

  var markSeen = function(roomData, message, current_user_id) {
    if (message.userId == current_user_id) {
      if (roomData.roomType  === 'dirrect') {
        return `<span class="align-middle send-status">${ message.notSeenCount > 0 ? '<i class="ri-check-line"></i>' : '<i class="ri-check-double-line"></i>' }</span>`;
      } else if (['group_chat', 'zero_group'].includes(roomData.roomType)) {
        return `<span class="align-middle send-status">${ message.notSeenCount == (roomData.memberCount - 1) ? '<i class="ri-check-line"></i>' : '<i class="ri-check-double-line"></i>' }</span>`;
      }
    }
    return '';
  }

  var renderPollOptions = function(options, current_user_id) {
    let data = "";
    options.forEach(function(option) {
      data += `<button option-id="${option.id}" class="btn poll-button ${option.votes.includes(current_user_id) ? 'voted' : ''}">${ htmlEncode(option.title) }<span style="float: right;" class="vote-count">${option.votes.length === 0 ? '' : option.votes.length}</span></button>`;
    })
    return data;
  }

  var pollContent = function(data, roomData, current_user_id, customName, timeSent) {
    var avatarContent = ``
    if (data.userId !== current_user_id) {
      const currentUserData = roomData.usersData.find((user) => user.id == data.userId)
      const avatarPath = currentUserData ? currentUserData.avatar : imagePath('./heroes/s_default')
      avatarContent = `<div class="chat-avatar">
        <img src="${avatarPath}" alt="">
      </div>`
    }
    return `<li data-long-press-delay="500" class="${data.userId === current_user_id ? 'right' : 'left'} poll-message conversation-messages" message-type="${ data.messageType }" id="message-${ data.id }">
      <div class="round">
        <input type="checkbox" disabled="disabled" class="message-check" id="checkbox-${ data.id }" />
        <label for="checkbox-${ data.id }"></label>
      </div>
      <div class="conversation-list">
        ${avatarContent}
        <div class="right-block">
          <div class="user-chat-content">
            <div class="ctext-wrap">
              <div class="ctext-wrap-content">
                ${data.userId !== current_user_id ? `<div class="conversation-name custom-fullname-${data.userId}">${ roomData.roomType == 'broadcast' ? '' : htmlEncode(customName || data.name) }</div>` : '' }
                <h5 style="font-weight: 500">${ htmlEncode(data.poll.question) }</h5>
                ${renderPollOptions(data.poll.options, current_user_id)}

                <p>Expires: <span class="simple-timer-${data.id}"></span></p>
                <hr style="min-width:265px;" />
                <div>
                  <span style="display: inline-flex;">
                    <i class="ri-pie-chart-2-line" style="margin-right: 5px;"></i> Poll
                  </span>
                </div>
              </div>
            </div>
            <div class="chat-time mb-0">
              <span class="align-middle">${timeSent}</span>
              ${ markSeen(roomData, data, current_user_id) }
            </div>
          </div>
          ${ ["group_chat", "zero_group"].includes(roomData.roomType) ? '<p class="seen-user-list">...</p>' : '' }
        </div>
      </div>
    </li>`;
  }

  var voiceMessageContent = function(data, roomData, current_user_id, customName, timeSent) {
    var avatarContent = ``
    if (data.userId !== current_user_id) {
      const currentUserData = roomData.usersData.find((user) => user.id == data.userId)
      const avatarPath = currentUserData ? currentUserData.avatar : imagePath('./heroes/s_default')
      avatarContent = `<div class="chat-avatar">
        <img src="${avatarPath}" alt="">
      </div>`
    }

    return `<li data-long-press-delay="500" class="${data.userId === current_user_id ? 'right' : 'left'} conversation-messages" message-type="${ data.messageType }" id="message-${ data.id }">
      <div class="round">
        <input type="checkbox" disabled="disabled" class="message-check" id="checkbox-${ data.id }" />
        <label for="checkbox-${ data.id }"></label>
      </div>
      <div class="conversation-list">
        ${avatarContent}
        <div class="right-block">
          <div class="user-chat-content">
            <div class="ctext-wrap">
              <div class="ctext-wrap-content">
                ${data.userId !== current_user_id ? `<div class="conversation-name custom-fullname-${data.userId}">${ roomData.roomType == 'broadcast' ? '' : htmlEncode(customName || data.name) }</div>` : '' }
                ${forwardContent(data.authorName)}
                ${replyContent(data.replyMessageContent, data.replyMessageFullname, roomData, data)}
                <div class="voice-block">
                  <div class="media-button">
                    <i class="ri-play-fill"></i>
                    <i class="ri-pause-fill"></i>
                  </div>
                  <div class="wave-block" id="voice-message-${ data.id }"></div>
                  <div class="voice-duration voice-duration-${data.id}">00:00</div>
                </div>
              </div>
            </div>
            <div class="chat-time mb-0">
              ${deleteDateBlock(data)}
              <span class="align-middle">${timeSent}</span>
              ${ markSeen(roomData, data, current_user_id) }
            </div>
          </div>
          ${ ["group_chat", "zero_group"].includes(roomData.roomType) ? '<p class="seen-user-list">...</p>' : '' }
        </div>
      </div>
    </li>`
  }

  var fileMessageContent = function(data, roomData, current_user_id, customName, timeSent) {
    let fileSource = '';
    data.messageFiles.forEach(function(file) {
      fileSource += `
        <div class="${ data.messageType == 'csv' ? 'each-secured-book' : 'each-file'}" token="${ file['token'] }">
          <div class="file-icon">
            <i class="${ data.messageType == 'csv' ? 'ri-file-excel-2-fill' : 'ri-file-fill'}"></i>
          </div>
          <div class="file-detail">
            <p>${ htmlEncode(file['title']) }.${ data.messageType == 'csv' ? 'csv' : 'txt'}</p>
          </div>
        </div>
      `
    });

    var avatarContent = ``
    if (data.userId !== current_user_id) {
      const currentUserData = roomData.usersData.find((user) => user.id == data.userId)
      const avatarPath = currentUserData ? currentUserData.avatar : imagePath('./heroes/s_default')
      avatarContent = `<div class="chat-avatar">
        <img src="${avatarPath}" alt="">
      </div>`
    }
    return `<li data-long-press-delay="500" class="${data.userId === current_user_id ? 'right' : 'left'} conversation-messages" message-type="${ data.messageType }" id="message-${ data.id }">
      <div class="round">
        <input type="checkbox" disabled="disabled" class="message-check" id="checkbox-${ data.id }" />
        <label for="checkbox-${ data.id }"></label>
      </div>
      <div class="conversation-list">
        ${avatarContent}
        <div class="right-block">
          <div class="user-chat-content">
            <div class="ctext-wrap">
              <div class="ctext-wrap-content">
                ${data.userId !== current_user_id ? `<div class="conversation-name custom-fullname-${data.userId}">${ roomData.roomType == 'broadcast' ? '' : htmlEncode(customName || data.name) }</div>` : '' }
                ${forwardContent(data.authorName)}
                ${replyContent(data.replyMessageContent, data.replyMessageFullname, roomData, data)}
                <p class="mb-1 message-content">${ htmlEncode(data.message) }</p>
                ${fileSource}
              </div>
            </div>
            <div class="chat-time mb-0">
              ${deleteDateBlock(data)}
              <span class="align-middle">${timeSent}</span>
              ${ markSeen(roomData, data, current_user_id) }
            </div>
          </div>
          ${ ["group_chat", "zero_group"].includes(roomData.roomType) ? '<p class="seen-user-list">...</p>' : '' }
        </div>
      </div>
    </li>`
  }

  var imageMessageContent = function(data, roomData, current_user_id, customName, timeSent) {
    var imageSource = "";
    const imageSize = data.messageImages.length;
    let temp = 0;

    data.messageImages.forEach(function(image) {
      const imageData = image[0]
      const fileType = image[1]
      if (temp % 2 === 0) {
        imageSource += `<ul class="list-inline message-img mb-0 row">`
      }

      if (fileType == 'video') {
        imageSource += `
        <li class="list-inline-item message-img-list ${imageSize === 1 ? 'col-12 only-one-image' : 'col-6'} each-message-photo">
          <div>
            <div class="view-photo d-inline-block" raw_image="${imageData}">
              <video src="${imageData}" class="rounded border"></video>
              <label class="is-video">Video</label>
            </div>
          </div>
        </li>`
      } else {
        imageSource += `
        <li class="list-inline-item message-img-list ${imageSize === 1 ? 'col-12 only-one-image' : 'col-6'} each-message-photo">
          <div>
            <div data-fancybox="${roomData.id}" data-src="${imageData}" class="view-photo d-inline-block">
              <img src="${imageData}" class="rounded border">
            </div>
          </div>
        </li>`
      }

      if (temp % 2 !== 0 || temp === imageSize - 1) {
        imageSource += `</ul>`
      }
      temp ++;
    });

    var avatarContent = ``
    if (data.userId !== current_user_id) {
      const currentUserData = roomData.usersData.find((user) => user.id == data.userId)
      const avatarPath = currentUserData ? currentUserData.avatar : imagePath('./heroes/s_default')
      avatarContent = `<div class="chat-avatar">
        <img src="${avatarPath}" alt="">
      </div>`
    }

    return `<li data-long-press-delay="500" class="${data.userId === current_user_id ? 'right' : 'left'} conversation-messages" message-type="${ data.messageType }" id="message-${ data.id }">
      <div class="round">
        <input type="checkbox" disabled="disabled" class="message-check" id="checkbox-${ data.id }" />
        <label for="checkbox-${ data.id }"></label>
      </div>
      <div class="conversation-list">
        ${avatarContent}
        <div class="right-block">
          <div class="user-chat-content">
            <div class="ctext-wrap">
              <div class="ctext-wrap-content">
                ${data.userId !== current_user_id ? `<div class="conversation-name custom-fullname-${data.userId}">${ roomData.roomType == 'broadcast' ? '' : htmlEncode(customName || data.name) }</div>` : '' }
                ${forwardContent(data.authorName)}
                ${replyContent(data.replyMessageContent, data.replyMessageFullname, roomData, data)}
                <p class="mb-0 message-content">${ htmlEncode(data.message) }</p>
                ${imageSource}
              </div>
            </div>
            <div class="chat-time mb-0">
              ${deleteDateBlock(data)}
              <span class="align-middle">${timeSent}</span>
              ${ markSeen(roomData, data, current_user_id) }
            </div>
          </div>
          ${ ["group_chat", "zero_group"].includes(roomData.roomType) ? '<p class="seen-user-list">...</p>' : '' }
        </div>
      </div>
    </li>`
  }

  var messageContent = function(data, roomData, current_user_id, customName, timeSent) {
    var avatarContent = ``
    if (data.userId !== current_user_id) {
      const currentUserData = roomData.usersData.find((user) => user.id == data.userId)
      const avatarPath = currentUserData ? currentUserData.avatar : imagePath('./heroes/s_default')
      avatarContent = `<div class="chat-avatar">
        <img src="${avatarPath}" alt="">
      </div>`
    }
    return `<li data-long-press-delay="500" class="${data.userId === current_user_id ? 'right' : 'left'} conversation-messages" message-type="${ data.messageType }" id="message-${ data.id }">
      <div class="round">
        <input type="checkbox" disabled="disabled" class="message-check" id="checkbox-${ data.id }" />
        <label for="checkbox-${ data.id }"></label>
      </div>
      <div class="conversation-list">
        ${avatarContent}
        <div class="right-block">
          <div class="user-chat-content">
            <div class="ctext-wrap">
              <div class="ctext-wrap-content">
                ${data.userId !== current_user_id ? `<div class="conversation-name custom-fullname-${data.userId}">${ roomData.roomType == 'broadcast' ? '' : htmlEncode(customName || data.name) }</div>` : '' }
                ${forwardContent(data.authorName)}
                ${replyContent(data.replyMessageContent, data.replyMessageFullname, roomData, data)}
                <p class="mb-0 message-content">${fixMentionName(htmlEncodeMessage(data.message))}</p>
              </div>
            </div>

            <div class="chat-time mb-0">
              ${deleteDateBlock(data)}
              <span class="align-middle">${timeSent}</span>
              ${ markSeen(roomData, data, current_user_id) }
            </div>
          </div>
          ${ ["group_chat", "zero_group"].includes(roomData.roomType) ? '<p class="seen-user-list">...</p>' : '' }
        </div>
      </div>
    </li>`
  }

  function fixMentionName(textData) {
    let isChanged = false
    const newMessage = textData.replace(/\B@\w+/g, function(match, tag, content) {
      const userId = match.slice(1)
      const fullName = $(`.custom-fullname-${userId}:first`).text()
      if (fullName) {
        isChanged = true
        return `<a class="mention-data" data-id= "${userId}" href="javascript:void(0)">${fullName}</a> `
      } else {
        return match;
      }
    })
    if (isChanged) {
      return newMessage
    } else {
      return textData;
    }
  }
}).call(this);
