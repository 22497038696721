// manage on pills-vaults (home page)
(function() {
  var videos = document.getElementsByClassName("vault-gallery-video");
  // current video stream
  let videoStream;
  let recorder;
  // video constraints
  var constraints = {
    video: {
      width: { ideal: 1280 },
      height: { ideal: 960 }
    },
    audio: {
      sampleRate: 48000,
      channelCount: 1,
      volume: 0,
      echoCancellation: true,
      noiseSuppression: true,
    },
  };
  // use front face camera
  let useFrontCamera = false;
  let choose_vault_file_id = [];

  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    switchTab();
    closeTab();
    handleTakeCamera();
    handleTake();
    handleTakeVideo();
    handleOpenFlashLight();
    handleStopTakeCamera();
    handleReTake();
    handleUploadVaultPhoto();
    handleViewPhoto();
    handlePickFile();
    handleResetChecked();
    handleDeleteFile();
    handleRecordVideo();
    handleBackToPhoto();
    handleStopAndSaveVideo();
    handleOpenSeachBox();
    handleCloseSearchBox();
    handleClearSearch();
    handleSearchNote();
    handleTakeNativeCamera()
    handleTakeNativeVideo()

  }

  var handleTakeNativeVideo = function() {
    $('#videoInput').on('change', function(event) {
      const file = event.target.files[0];
      if (file) {
        var fileReader = new FileReader();
        var loadingContent
        fileReader.onload = function(e) {
          var blob = new Blob([fileReader.result], {type: file.type});
          var url = URL.createObjectURL(blob);
          var video = document.createElement('video');
          var timeupdate = function() {
            if (snapImage()) {
              video.removeEventListener('timeupdate', timeupdate);
              video.pause();
            }
          };
          video.addEventListener('loadeddata', function() {
            if (snapImage()) {
              video.removeEventListener('timeupdate', timeupdate);
            }
          });
          var snapImage = function() {
            var canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
            var imageUrl = canvas.toDataURL();
            var success = imageUrl.length > 100000;
            if (success) {
              uploadNativeData(imageUrl)
              URL.revokeObjectURL(url);
            }
            return success;
          };
          video.addEventListener('timeupdate', timeupdate);
          video.preload = 'metadata';
          video.src = url;
          // Load video in Safari / IE11
          video.muted = true;
          video.playsInline = true;
          video.play();
        }
        fileReader.readAsArrayBuffer(file);
      }
    });
  }

  var handleTakeNativeCamera = function() {
    $('#cameraInput').on('change', function(event) {
      const file = event.target.files[0];
      if (file) {
        uploadNativeImageData(file, 'image')
      }
    });
  }

  async function uploadNativeImageData(file, type) {
    $("#pills-vaults #file-type").val(type);
    const imageUrl = window.URL.createObjectURL(file)
    const loadingContent = $(`<div class="gallery-column photo-block loading-content">
      <img class="loading-gif" src="/images/loading1.gif">
      <img src="${imageUrl}">
    </div>`);
    const attachmentLoadingContent = $(`<div class="gallery-column photo-block loading-content">
      <img class="loading-gif" src="/images/loading1.gif">
      <img src="${imageUrl}">
    </div>`);
    $('#pills-vaults .take-photo-block').after(loadingContent);
    $('#attachment-chat .take-photo-block').after(attachmentLoadingContent);

    let loading = true;
    var result;
    var formData = new FormData($("form#vault-upload-photo-form")[0]);

    await $.ajax({
      url: "/api/v1/vaults/upload_file",
      type: "POST",
      async: true,
      data: formData,
      processData: false,
      contentType: false,
      success: function(data) {
        $('#pills-vaults #file-type').val('');
        $("#pills-vaults #cameraInput").val('');
        result =  data.data;
        loading = false
      }
    });

    if (!loading) {
      var renderSource = `<a class="fancybox-all" data-fancybox="gallery" data-caption="${result.createdAt}" data-src="${result.rawImage}">
      <img loading="lazy" src="${result.thumb}">
      </a>`

      var galleryContent = `<div class='gallery-column photo-block'>
        <input type='checkbox' class='gallery-checkbox' id='gallery-checkbox-${result.id}' />
        <label for='gallery-checkbox-${result.id}' class='gallery-label'></label>
        ${renderSource}
      </div>`;

      var vaultGalleryContent = `<div class='gallery-column photo-block'>
        <input type="checkbox" class='gallery-checkbox' id="vault-checkbox-${result.id}" />
        <label for="vault-checkbox-${result.id}" class='gallery-label'></label>
        ${renderSource}
      </div>`;
      loadingContent.replaceWith(vaultGalleryContent);
      attachmentLoadingContent.replaceWith(galleryContent);
    }
  }

  async function uploadNativeData(imageUrl) {
    $("#pills-vaults #file-type").val("video");

    var loadingContent = $(`<div class="gallery-column photo-block loading-content">
      <img class="loading-gif" src="/images/loading1.gif">
      <img src="${imageUrl}">
    </div>`);

    var attachmentLoadingContent = $(`<div class="gallery-column photo-block loading-content">
      <img class="loading-gif" src="/images/loading1.gif">
      <img src="${imageUrl}">
    </div>`);

    $('#pills-vaults .take-photo-block').after(loadingContent);
    $('#attachment-chat .take-photo-block').after(attachmentLoadingContent);

    let loading = true;
    var result;
    var formData = new FormData($("form#vault-upload-photo-form")[0]);

    await $.ajax({
      url: "/api/v1/vaults/upload_file",
      type: "POST",
      async: true,
      data: formData,
      processData: false,
      contentType: false,
      success: function(data) {
        $('#pills-vaults #file-type').val('');
        $("#pills-vaults #videoInput").val('');

        result =  data.data;
        loading = false
      }
    });

    if (!loading) {
      var renderSource = `<img loading="lazy" src="${result.thumb}" class="data-enlargeable each-gallery-video" raw_image="${result.rawImage}">
      <label class="is-video">Video</label>`

      var galleryContent = `<div class='gallery-column photo-block'>
        <input type='checkbox' class='gallery-checkbox' id='gallery-checkbox-${result.id}' />
        <label for='gallery-checkbox-${result.id}' class='gallery-label'></label>
        ${renderSource}
      </div>`;

      var vaultGalleryContent = `<div class='gallery-column photo-block'>
        <input type="checkbox" class='gallery-checkbox' id="vault-checkbox-${result.id}" />
        <label for="vault-checkbox-${result.id}" class='gallery-label'></label>
        ${renderSource}
      </div>`;
      loadingContent.replaceWith(vaultGalleryContent);
      attachmentLoadingContent.replaceWith(galleryContent);
    }
  }

  var renderPreUpload = function(imageUrl) {
    const loadingContent = $(`<div class="gallery-column photo-block loading-content">
      <img class="loading-gif" src="/images/loading1.gif">
      <img src="${imageUrl}">
    </div>`);
    $('#pills-vaults .take-photo-block').after(loadingContent);
    // $('#attachment-chat .take-photo-block').after(loadingContent);
  }

  var handleOpenSeachBox = function() {
    $(".detail-page .search-note-icon").on("click", function() {
      $(".detail-page #vaut-main-topbar").hide();
      $(".detail-page .valt-search-block").show();
      document.getElementById("note-search-keyword").focus();
    })
  }

  var handleCloseSearchBox = function() {
    $(".detail-page .valt-search-block .back-btn").on("click", function() {
      $(".detail-page #vaut-main-topbar").show();
      $(".detail-page .valt-search-block").hide();
      $("#vault-listing-files .gallery-column").css("display", "block")
      $(".detail-page .valt-search-block .keyword").val("")
    })
  }

  var handleClearSearch = function() {
    $(".detail-page .valt-search-block .close-search").on("click", function() {
      $(".detail-page .valt-search-block .keyword").val("")
      $("#vault-listing-files .gallery-column").css("display", "block")
    })
  }

  var handleSearchNote = function() {
    $(".detail-page .valt-search-block .keyword").keyup(_.debounce(function() {
      const keyword = this.value

      if (!keyword) {
        $("#vault-listing-files .gallery-column").css("display", "block")
        return
      }
      $(".preload-decrypt").show();

      $.ajax({
        url: "/api/v1/vault_notes/search",
        type: "POST",
        data: { keyword: keyword },
        success: function(data) {
          const filter = data.data.ids
          $("#vault-listing-files .gallery-column").each(function () {
            if (filter.includes($(this).find('.vault-content-block').attr("token"))) {
              $(this).show()
            } else {
              $(this).hide();
            }
          });
          $(".preload-decrypt").hide();
        }
      });
    }, 500));
  }

  var handleDeleteFile = function() {
    $("#delete-file-button").on("click", function() {
      $.ajax({
        url: "/api/v1/vaults/delete_file",
        type: "DELETE",
        async: false,
        data: { choose_vault_file_id: choose_vault_file_id },
        success: function(data) {
          if (data.code === 500) {
            showToast(data.message);
          } else {
            showToast("Delete completed!")
            choose_vault_file_id.forEach(function(id) {
              $(`#vault-checkbox-${id}`).parent().remove()
              $(`#gallery-checkbox-${id}`).parent().remove()
            });
          }
        }
      });

      $("#remove-file-modal").modal("toggle");
      $("#pills-vaults .gallery-checkbox").prop('checked', false);
      $('#vault-tool-topbar').hide();
      $('#vaut-main-topbar').show();
      choose_vault_file_id = [];
    })
  }

  var handleResetChecked = function() {
    $("#pills-vaults .vault-checkbox-topbar-remove").on("click", function() {
      choose_vault_file_id = [];
      $("#pills-vaults .gallery-checkbox").prop('checked', false);
      $('#vault-tool-topbar').hide();
      $('#vaut-main-topbar').show();
    })
  }

  var handlePickFile = function() {
    $("#pills-vaults").delegate(".gallery-checkbox", "click", function() {
      chooseFile(this);
    })
  }

  var chooseFile = function(el) {
    var checked = $('#pills-vaults .gallery-checkbox:checked').length;
    var id = el.id.split('vault-checkbox-')[1];
    if ($(el).prop('checked')) {
      choose_vault_file_id.push(id);
    } else {
      choose_vault_file_id = choose_vault_file_id.filter(i => i != id);
    }

    if (checked > 0) {
      $('#vault-tool-topbar').show();
      $('#vaut-main-topbar').hide();
      $('#vault-tool-topbar .file-selected-number').html(checked);
    } else {
      $('#vault-tool-topbar').hide();
      $('#vaut-main-topbar').show();
      $('#vault-tool-topbar .file-selected-number').html('');
    }
  }

  var handleViewPhoto = function() {
    // view on vault
    // view on vault conversation
    Fancybox.bind('#pills-vaults [data-fancybox="gallery"]', {
      Toolbar: {
        items: {
          edit: {
            tpl: '<button class="f-button start-edit-btn" title="Edit" data-fancybox-download>Edit</button>',
          }
        },
        display: {
          left: [
            "infobar",
          ],
          middle: [],
          right: [
            "edit",
            "slideshow",
            "close",
          ],
        }
      }
    })
    Fancybox.bind('#attachment-chat [data-fancybox="gallery"]', {
      Toolbar: {
        items: {
          edit: {
            tpl: '<button class="f-button start-edit-btn" title="Edit" data-fancybox-download>Edit</button>',
          }
        },
        display: {
          left: [
            "infobar",
          ],
          middle: [],
          right: [
            "edit",
            "slideshow",
            "close",
          ],
        }
      }
    })

    $("#vault-listing-gallery").delegate(".each-gallery-video", "click", function() {
      showModalVideo(this)
    })

    $("#listing-gallery").delegate(".each-gallery-video", "click", function() {
      showModalVideo(this)
    })
  }

  var handleUploadVaultPhoto = function() {
    $("#vault-upload-photo-form").submit(function(e) {
      uploadData();
      stopVideoStream();
      return false;
    });
  }

  async function uploadData() {
    let loading = true;
    // $(".preload-encrypt").show();
    var result;
    const datastring = $("form#vault-upload-photo-form").serialize();
    var loadingContent = $(`<div class="gallery-column photo-block">
      <img class="lazy data-enlargeable" src="/images/lazyload-encrypt.gif">
    </div>`);
    $('#pills-vaults .take-photo-block').after(loadingContent);

    $("#pills-vaults .start-camera").hide();
    $('#pills-vaults .vault-gallery-screenshot').show();
    $('#pills-vaults .detail-page').show();
    $(".side-menu").show();
    // $("attachment-chat .simplebar-content-wrapper").scrollTop(0);

    // $('#attachment-tab-gallery').addClass('checked');
    // $(`.gallery-tab`).show();
    $('#pills-vaults .vault-gallery-screenshot').hide();
    $('#pills-vaults .vault-gallery-camera').show();
    $('#pills-vaults #vault-screenshot').html('');
    $('#pills-vaults #attach-file-url').val('');
    $('#pills-vaults #file-type').val('');

    await $.ajax({
      url: "/api/v1/vaults/upload_file",
      type: "POST",
      async: true,
      data: datastring,
      success: function(data) {
        result =  data.data;
        loading = false
      }
    });

    if (!loading) {
      loadingContent.remove();
      var galleryContent = `<div class='gallery-column photo-block'>
        <input type='checkbox' class='gallery-checkbox' id='gallery-checkbox-${result.id}' />
        <label for='gallery-checkbox-${result.id}' class='gallery-label'></label>
        <a class="fancybox-all" data-fancybox="gallery" data-caption="${result.createdAt}" data-src="${result.rawImage}">
          <img loading="lazy" src="${result.thumb}">
        </a>
      </div>`;
      $('#attachment-chat .take-photo-block').after(galleryContent);

      var vaultGalleryContent = `<div class='gallery-column photo-block'>
        <input type="checkbox" class='gallery-checkbox' id="vault-checkbox-${result.id}" />
        <label for="vault-checkbox-${result.id}" class='gallery-label'></label>
        <a class="fancybox-all" data-fancybox="gallery" data-caption="${result.createdAt}" data-src="${result.rawImage}">
          <img loading="lazy" src="${result.thumb}">
        </a>
      </div>`;
      $('#pills-vaults .take-photo-block').after(vaultGalleryContent);

      // $(".preload-encrypt").hide();
    }
  }

  var handleReTake = function() {
    $('#pills-vaults .vault-gallery-screenshot .attachment-camera-section-remove').on('click', function() {
      $('.vault-gallery-screenshot').hide();
      $('.vault-gallery-camera').show();
      $('#pills-vaults #vault-screenshot').html('');
    })
  }

  var handleStopTakeCamera = function() {
    $("#pills-vaults .vault-gallery-camera .attachment-camera-section-remove").on("click", function() {
      $(".chat-leftsidebar").css("height", "96vh")
      $("#pills-vaults .start-camera").hide();
      $('#pills-vaults .detail-page').show();
      $(".side-menu").show();

      $(".flash-light-btn").removeClass("active")
      if (videoStream) {
        videoStream.getVideoTracks()[0].applyConstraints({
          advanced: [{torch: false}]
        });
      }

      stopVideoStream();
    })
  }

  function handleTakeCamera() {
    $('#pills-vaults .attachment-camera').on('click', async function(e) {
      let allowMic = true;
      let allowCam = true;

      try {
        const camPermission = Permission.checkCamPermission()

        if (!camPermission) {
          allowCam = false;
          Permission.cameraPermission();
          return false;
        }
      } catch (err) {}

      try {
        const micPermission = Permission.checkMicPermission()
        if (!micPermission) {
          allowMic = false;
          Permission.voicePermission();
          return false;
        }
      } catch (err) {}

      if (allowMic && allowCam) {
        processStartCamera();
      }
    });
  }

  var processStartCamera = function() {
    $('#pills-vaults .vault-gallery-screenshot').hide();
    $('#pills-vaults .detail-page').hide();
    $("#pills-vaults .start-camera").show();
    $(".side-menu").hide();
    $("#pills-vaults .switch-video-photo").show();
    $("#pills-vaults .switch-video-photo .take-photo-option").addClass('active')
    $("#pills-vaults .switch-video-photo .take-video-option").removeClass('active')

    $("#pills-vaults #btn-screenshot").show();
    $("#pills-vaults #btn-record-video").hide();
    $("#pills-vaults #btn-stop-and-save-video").hide();
    $(".chat-leftsidebar").css("height", "100vh")

    initializeCamera();
  }

  var handleRecordVideo = function() {
    $(".take-video-option").on("click", function() {
      $(".switch-video-photo .take-photo-option").removeClass('active')
      $(".switch-video-photo .take-video-option").addClass('active')
      $("#btn-screenshot, #btnScreenshot").hide();
      $("#btn-record-video, #btnRecordVideo").show();
    })
  }

  var handleBackToPhoto = function() {
    $(".take-photo-option").on("click", function() {
      $(".switch-video-photo .take-photo-option").addClass('active')
      $(".switch-video-photo .take-video-option").removeClass('active')
      $("#btn-screenshot, #btnScreenshot").show();
      $("#btn-record-video, #btnRecordVideo").hide();
    })
  }
  var handleOpenFlashLight = function() {
    $("#pills-vaults .flash-light-btn").on("click", function() {
      if($(this).hasClass("active")) {
        $(this).removeClass("active")
        videoStream.getVideoTracks()[0].applyConstraints({
          advanced: [{torch: false}]
        });
      } else {
        $(this).addClass("active")
        videoStream.getVideoTracks()[0].applyConstraints({
          advanced: [{torch: true}]
        });
      }
    })
  }

  var handleTake = function() {
    $("#pills-vaults #btn-screenshot").on("click", function () {
      screenShot();
      $('#pills-vaults .vault-gallery-camera').hide();
      $('#pills-vaults .vault-gallery-screenshot').show();

      $(".flash-light-btn").removeClass("active")
      videoStream.getVideoTracks()[0].applyConstraints({
        advanced: [{torch: false}]
      });
    });
  }

  var handleTakeVideo = function() {
    $("#pills-vaults #btn-record-video").on("click", function () {
      $("#pills-vaults .switch-video-photo").hide();
      $("#pills-vaults #btn-record-video").hide();
      $("#pills-vaults #btn-stop-and-save-video").show();

      recorder = new MediaRecorder(videoStream, {
        mimeType: 'video/webm'
      });
      recorder.start();
    });
  }

  var handleStopAndSaveVideo = function() {
    $("#pills-vaults #btn-stop-and-save-video").on("click", function () {
      $("#pills-vaults .start-camera").hide();
      $('#pills-vaults .detail-page').show();
      $(".side-menu").show();

      recorder.ondataavailable = e => {
        uploadVideo(e.data)
      };
      recorder.stop();

      $(".flash-light-btn").removeClass("active")
      videoStream.getVideoTracks()[0].applyConstraints({
        advanced: [{torch: false}]
      });
      stopVideoStream();
    });
  }

  async function uploadVideo(data) {
    let loading = true
    var result
    var loadingContent = $(`<div class="gallery-column">
      <video class="lazy data-enlargeable each-gallery-video" raw_image="${URL.createObjectURL(data)}" src="${URL.createObjectURL(data)}"></video>
      <label class="is-video">Uploading</label>
    </div>`);
    $('#pills-vaults .take-photo-block').after(loadingContent);

    $("#pills-vaults .start-camera").hide();
    $('#pills-vaults .vault-gallery-screenshot').show();
    $('#pills-vaults .detail-page').show();
    $(".side-menu").show();
    $('#pills-vaults .vault-gallery-screenshot').hide();
    $('#pills-vaults .vault-gallery-camera').show();
    $('#pills-vaults #vault-screenshot').html('');
    $('#pills-vaults #attach-file-url').val('');
    $('#pills-vaults #file-type').val('');

    var fd = new FormData();
    fd.append('file_type', 'video');
    fd.append('attach_file_url', data);

    await $.ajax({
      url: "/api/v1/vaults/upload_file",
      type: "POST",
      async: true,
      data: fd,
      processData: false,
      contentType: false,
      success: function(data) {
        result =  data.data;
        loading = false
      }
    });

    if (!loading) {
      loadingContent.remove();
      var galleryContent = `<div class='gallery-column photo-block'>
        <input type='checkbox' class='gallery-checkbox' id='gallery-checkbox-${result.id}' />
        <label for='gallery-checkbox-${result.id}' class='gallery-label'></label>
        <img loading="lazy" src="${result.thumb}" class="data-enlargeable each-gallery-video" raw_image="${result.rawImage}">
        <label class="is-video">Video</label>
      </div>`;
      $('#attachment-chat .take-photo-block').after(galleryContent);

      var vaultGalleryContent = `<div class='gallery-column photo-block'>
        <input type="checkbox" class='gallery-checkbox' id="vault-checkbox-${result.id}" />
        <label for="vault-checkbox-${result.id}" class='gallery-label'></label>
        <img loading="lazy" src="${result.thumb}" class="data-enlargeable each-gallery-video" raw_image="${result.rawImage}">
        <label class="is-video">Video</label>
      </div>`;
      $('#pills-vaults .take-photo-block').after(vaultGalleryContent);

      // $(".preload-encrypt").hide();
    }
  }

  // screenshot
  var screenShot = function() {
    var img = document.createElement("img");
    var canvas = document.getElementById("vault-canvas");
    canvas.width = videos[0].videoWidth;
    canvas.height = videos[0].videoHeight;
    canvas.getContext("2d").drawImage(videos[0], 0, 0);
    img.src = canvas.toDataURL("image/jpeg");
    $("#pills-vaults #vault-screenshot").prepend(img);
    $("#pills-vaults #attach-file-url").val(img.src);
    $("#pills-vaults #file-type").val('image');
  }

  var switchTab = function() {
    $("#pills-vaults #vault-tab-file").on("click", function() {
      $(".main-page").hide();
      $(".detail-page").show();
      $(".detail-page .conversation-tab").hide();
      $(".detail-page .gallery-tab").hide();
      $(".detail-page .secured-book-tab").hide();
      $(".detail-page .file-tab").show();
      $(".detail-page .search-note-icon").show();
    })

    $("#pills-vaults #vault-tab-secured-book").on("click", function() {
      $(".main-page").hide();
      $(".detail-page").show();
      $(".detail-page .conversation-tab").hide();
      $(".detail-page .gallery-tab").hide();
      $(".detail-page .file-tab").hide();
      $(".detail-page .secured-book-tab").show();
      $(".detail-page .search-note-icon").hide();
    })

    $("#pills-vaults #vault-tab-gallery").on("click", function() {
      $(".main-page").hide();
      $(".detail-page").show();
      $(".detail-page .conversation-tab").hide();
      $(".detail-page .file-tab").hide();
      $(".detail-page .secured-book-tab").hide();
      $(".detail-page .gallery-tab").show();
      $(".detail-page .search-note-icon").hide();

      lazyLoadImg();

      $('body').bind('touchmove', function(e) {
        lazyLoadImg();
      });
    })

    $("#pills-vaults #vault-tab-conversation").on("click", function() {
      $(".main-page").hide();
      $(".detail-page").show();
      $(".detail-page .gallery-tab").hide();
      $(".detail-page .file-tab").hide();
      $(".detail-page .secured-book-tab").hide();
      $(".detail-page .conversation-tab").show();
      $(".detail-page .search-note-icon").hide();

      lazyLoadImg();

      $('body').bind('touchmove', function(e) {
        lazyLoadImg();
      });
    })
  }

  var closeTab = function() {
    $('#pills-vaults .attachment-chat-remove').on('click', function() {
      $(".detail-page .file-tab").hide();
      $(".detail-page .secured-book-tab").hide();
      $(".detail-page").hide();
      $(".main-page").show();
      $("#pills-vaults .start-camera").hide();
    })
  }

  // Lazy load image
  function lazyLoadImg() {
    setTimeout(function() {
      $("img.lazy").lazyload({
        effect: "fadeIn"
      })
    }, 200);
  }

  // stop video stream
  var stopVideoStream = function() {
    if (videoStream) {
      videoStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  // initialize
  var initializeCamera = async function() {
    stopVideoStream();
    constraints.video.facingMode = useFrontCamera ? "user" : "environment";

    try {
      videoStream = await navigator.mediaDevices.getUserMedia(constraints);

      videos.forEach(function(video) {
        video.srcObject = videoStream;
        video.muted = true
        video.play();
      })
    } catch (err) {

    }
  }

  var showModalVideo = function(el) {
    var src = $(el).attr('raw_image');
    var modal;
    var backBtn;

    function removeModal() {
      modal.remove();
      backBtn.remove();
      $('body').off('keyup.modal-close');
    }

    backBtn = $(`<div style="position: absolute; top: 10px; right: 20px; z-index: 10001; font-size: 35px; color:white"><i class="ri-close-line"></i></div>`)
    backBtn.click(function() {
      removeModal();
    }).appendTo('body');

    modal = $('<div>').css({
      background: 'black',
      backgroundSize: 'contain',
      width: '100%',
      height: '100%',
      position: 'fixed',
      zIndex: '10000',
      top: '0',
      left: '0',
      cursor: 'zoom-out'
    }).html(`<video controls autoplay style='max-height: calc(100vh - 68px); width: 100%; margin-top: 68px;'><source src=${src} type='video/webm'></video>`).appendTo('body');
  }

}).call(this);
