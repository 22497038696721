(function(win, $) {
  var startX,
      startY,
      pullX,
      pullY,
      moveX,
      moveY,
      rem,
      btn,
      threshold;
  var _this;

  var PullDelete = function ($parent, callback) { // Receive the parent element as the first argument
    _this = this;
    btn = 80;
    threshold = btn / 2;

    $parent.delegate('.conversation-messages', 'touchstart', function(e) {
      startX = e.originalEvent.targetTouches[0].pageX;
      startY = e.originalEvent.targetTouches[0].pageY;
      $(this).removeClass('trans');
      $(this).siblings().addClass('trans').css({transform: 'translateX(0)'}); // 收起其他项
      $(this).append('<div class="pd_btn"><i class="ri-reply-fill"></i></div>');
    });

    $parent.delegate('.conversation-messages', 'touchmove', function(e) {
      pullX = e.originalEvent.targetTouches[0].pageX;
      pullY = e.originalEvent.targetTouches[0].pageY;
      moveX = (startX - pullX) * 0.8;
      moveY = (startY - pullY) * 0.8;
      // 阻止纵向推拽
      if (Math.abs(moveY) > Math.abs(moveX)) {
        return;
      }
      if (moveX <= btn) {
        $(this).css({transform: 'translateX(-' + moveX + 'px)'});
      }
    });

    $parent.delegate('.conversation-messages', 'touchend', function(e) {
      $(this).find(".pd_btn").remove()
      var trans = _this.transverseShift($(this)),
      viewTrans = trans >= threshold && trans < btn ? btn : 0; // 阈值范围内视为有效推拽
      $(this).addClass('trans').css({transform: 'translateX(-' + viewTrans + 'px)'});
      // 点击删除
      if (viewTrans && callback) {
        callback(this);
      }
    });
  }

  PullDelete.prototype = {
    constructor: PullDelete,
    transverseShift: function($dom) {
      if (!$dom instanceof jQuery) {
        return;
      }
      var trans = $dom.css('transform');
      if (trans.indexOf('matrix') > -1) {
        return Math.abs(trans.split(',')[4]) || 0;
      } else {
        return Math.abs(trans.replace(/[^\d\.]/g, '')) || 0;
      }
    }
    // Your existing methods here
  }

  $.fn.extend({
    pulldelete: function(callback){
      this.each(function(){
        new PullDelete($(this), callback);
      });
      return this;
    }
  });

  window.PullDelete = PullDelete;
})(window, jQuery);
