(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    selectPackage();
    copyQr();
    buyMore();
  }

  var buyMore = function() {
    $(".wallet-container .buymore-btn").on("click", function() {
      const paymentMessage = {
        "action": "request_payment",
        "payload": {
          "user_id": $(this).attr("user_id")
        }
      }
      Mobile.callNative(JSON.stringify(paymentMessage));
    })
  }

  var selectPackage = function() {
    var exchange = parseFloat($(".one-hundred-usd-to-xmr").val());
    const defaultUsd = calAmountUsd(6)
    $(".xmr-amount").text(calAmountXmr(defaultUsd, exchange));
    $(".usd-amount").text(`(${defaultUsd} USD)`)

    $(".month-select-group").on("click", function() {
      $(".month-select-group").removeClass("active");
      $(this).addClass("active");
      const type = $(this).attr("value");
      const amountUsd = calAmountUsd(type)
      $(".xmr-amount").text(calAmountXmr(amountUsd, exchange));
      $(".usd-amount").text(`(${amountUsd} USD)`)
    })
  }

  var calAmountUsd = function(type) {
    type = parseInt(type);
    switch(type) {
      case 3:
        return 350
      case 6:
        return 600
      case 12:
        return 1150
      default:
        return parseInt(type) * 100
    }
  }

  var calAmountXmr = function(usd, exchange) {
    // 100 usd = ?exchange XMR
    return Number(((usd * exchange)/100).toFixed(4))
  }

  var copyQr = function() {
    $("#copy-qr-button").on("click", function() {
      var $temp = $("<input>");
      $("body").append($temp);
      $temp.val($(".address-value").text()).select();
      document.execCommand("copy");
      $temp.remove();

      $(".toast-body").text("Already copied QR-code to clipboard");
      $(".toast-block").show();
      $('.toast').toast({ delay: 3000})
      $('.toast').toast("show")
      $(".toast-block").animate({width:'show'}, 3000);
    })
  }
}).call(this);


