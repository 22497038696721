(function() {
  $(document).on('turbolinks:load', function() {
    // init();
  });

  handleMention = function(roomData) {
    const roomId = roomData.id
    var cacheData
    $('#enter-message-main-chat').suggest('@', {
      data: function() {
        if (roomData.roomType === 'dirrect' || currentRoomType == 'broadcast') {
          return []
        } else {
          cacheData = $.getJSON(`/api/v1/users/room_member?room_id=${roomId}`);
          return cacheData
        }
      },
      map: function(user) {
        return {
          value: user.username,
          text: '<strong>'+user.username+'</strong> <small>'+user.fullname+'</small>'
        }
      }
    })

    if (roomData.roomType === 'dirrect' || currentRoomType == 'broadcast') {
      $("form#message-content .user-name-list").html("")
      return
    }

    userList = roomData.usersData
    if (userList) {
      const customUsername = userList.map(user => `<div class="d-none custom-fullname-${user.username}">${user.fullname}</div>`)
      $("form#message-content .user-name-list").html(customUsername)
    }
  }
}).call(this);
