(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
  	loadMorePhotos()
  }

  var loadMorePhotos = function() {
  	if (window.location.pathname == '/') {
  		$.ajax({
	      url: "/api/v1/vaults/next_photos_page?type=vault",
	      type: "GET",
	      async: true,
	      success: function(data) {
	        $("#pills-vaults .gallery-column.photo-block:last").after(data)
	      }
	    })
	    $.ajax({
	      url: "/api/v1/vaults/next_photos_page?type=attachment",
	      type: "GET",
	      async: true,
	      success: function(data) {
	        $("#attachment-chat .gallery-column.photo-block:last").after(data)
	      }
	    })
  	}
  }
}).call(this);
