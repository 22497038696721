var selectedElement
var matchElements = []
var currentPos

(function() {
  $(document).on('turbolinks:load', function() {
    init(selectedElement);
  });

  var init = function() {
    handleSearchMessage()
    handleEnterKeywork()
    handleStopSearch()
    handleClearSearchText()
    handleSearchUp()
    handleSearchDown()
  }

  var handleSearchMessage = function() {
    $("#user-chat-show").delegate(".start-search-message", "click", function() {
      $("#user-chat-show .chat-topnav").hide();
      $("#user-chat-show .search-block").removeClass("d-none")
      $("#user-chat-show .search-input").focus()

      $("#user-chat-show .search-message-control").show()
      $("#user-chat-show .chat-input-section").hide()

      const current_user_id = parseInt(sessionStorage.getItem('current_user_id'));
      const roomId = $('#my-chat-history').attr('room_id');
      const lastId = $('#my-chat-history').children().eq(1).attr("id")?.substring(8);

      if (!lastId) return

      var loadingPromise = new Promise(function(resolve, reject) {
        $(".progress-bar").show();
        $.ajax({
          url: `/api/v1/conversations/listing?room_id=${roomId}&last_id=${lastId}&get_all=true`,
          type: "GET",
          async: true,
          success: function(data) {
            roomData = data.data;
            totalData = roomData.conversations.length;
            resolve(roomData);
          },
          error: function(xhr, status, error) {
            reject(error);
          }
        });
      });

      loadingPromise.then(function(roomData) {
        if (totalData > 0) {
          handleAddMoreData(roomData, current_user_id, true, true)
        } else {
          $("#my-chat-history").prepend('<li class="date-break-line"><p class="date-content">No previous messages...</p></li>');
        }
      }).catch(function(error) {
        console.error("Error loading data:", error);
      }).finally(function() {
        setTimeout(function() {$(".progress-bar").hide()}, 300);
      });
    })
  }

  var handleEnterKeywork = function() {
    $("#user-chat-show .search-input").on('keyup input', function () {
      var keyword = $(this).val();
      if (keyword.length > 0) {
        $("#user-chat-show .remove-text-btn").show()
      } else {
        $("#user-chat-show .remove-text-btn").hide()
      }

      matchElements = []
      $("#my-chat-history li.conversation-messages").each(function () {
        if ($(this).find(".message-content").text().search(new RegExp(keyword, "i")) >= 0) matchElements.push(this)
      });

      if (!keyword) {
      	matchElements = []
      }
      currentPos = matchElements.length - 1
      searchControlResult(currentPos)
    })
  }

  var searchControlResult = function(current) {
  	if (selectedElement) $(selectedElement).css({ backgroundColor: 'transparent' });

  	const total = matchElements.length
    if (total > 0) {
    	selectedElement = matchElements[current]
    	gotoMessage(selectedElement)
    	$(".search-message-control .match-count").text(`${current + 1}/${total}`)
    } else {
    	$(".search-message-control .match-count").text("0")
    }
  }

  var handleSearchUp = function() {
  	$("#user-chat-show .search-message-control .up-btn").on('click', function () {
  		if (currentPos <= 0 || currentPos == undefined) return
  		currentPos = currentPos - 1
  		searchControlResult(currentPos)
  	})
  }

  var handleSearchDown = function() {
  	$("#user-chat-show .search-message-control .down-btn").on('click', function () {
  		if (currentPos == undefined || currentPos == matchElements.length - 1) return
  		currentPos = currentPos + 1
  		searchControlResult(currentPos)
  	})
  }

  var gotoMessage = function(matchElement) {
    if ($(matchElement).length > 0) {
      $(matchElement).css({ backgroundColor: '#00a0b052' });
      matchElement.scrollIntoView({
        behavior: 'auto'
      });
    }
  }

  var handleStopSearch = function() {
    $("#user-chat-show .stop-search-btn").on('click', function () {
    	if (selectedElement) $(selectedElement).css({ backgroundColor: 'transparent' });
      $("#user-chat-show .chat-topnav").show();
      $("#user-chat-show .search-block").addClass("d-none")
      $("#user-chat-show .search-input").val("")
      $("#user-chat-show .remove-text-btn").hide()
      $("#user-chat-show .search-message-control").hide()
      $("#user-chat-show .chat-input-section").show()

      matchElements = []
			currentPos = undefined
			$(".search-message-control .match-count").text("0")
    })
  }

  var handleClearSearchText = function() {
    $("#user-chat-show .remove-text-btn").on('click', function () {
    	if (selectedElement) $(selectedElement).css({ backgroundColor: 'transparent' });
      $("#user-chat-show .search-input").val("")
      $("#user-chat-show .remove-text-btn").hide()
      $("#user-chat-show .search-input").focus()

      matchElements = []
			currentPos = undefined
			$(".search-message-control .match-count").text("0")
    })
  }
}).call(this);
