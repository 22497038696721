import consumer from "./consumer"
import { printLastMessage, handleInitMessageGroup } from '../customs/share_module'

const messages_to_bottom = () => $("#user-chat-show .simplebar-content-wrapper").scrollTop($('#my-chat-history').prop("scrollHeight"))
let loadCount = 0;
let isDisconnect = false;
$(document).on('turbolinks:load', function() {
  if (typeof current_user_token === 'undefined' || current_user_token === "") {
    return;
  }

  loadCount += 1;
  if (loadCount > 1) {
    return;
  }

  consumer.subscriptions.create({
    channel: "RoomChannel",
    user_token: current_user_token,
  }, {
    connected() {
      // console.log("Connected to the chat room!");
      if (isDisconnect) {
        // window.location.href = "/"
        this.processSyncData()
        this.syncContactList()
        this.syncGroup()
      }
      isDisconnect = false
      // $(".waiting-network-loading").hide()
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      isDisconnect = true
      // $(".waiting-network-loading").show()
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      function getRoomData(roomId) {
        let result = {};
        $.ajax({
          url: `/api/v1/conversations/listing?room_id=${roomId}`,
          type: "GET",
          async: false,
          success: function(data) {
            roomList.push(data.data);
            result =  data.data;
          }
        });
        return result;
      }

      var conversationInitMessage = function(message, room_id) {
        return `<div class='template init-message-wrapper-${ room_id }'>
          <div class='wrapper'>
            <div>${ message }</div>
          </div>
        </div>`
      }

      var conversationInitMessageFull = function(message, room_id) {
        return `<div class='template init-message-wrapper-${ room_id }'>
          <div class="wrapper">
          <p class="text-center">${message}</p>

          <div>Secret chat:</div>
            <div style="font-size: 14px;">
              <div class="d-flex align-items-center pt-2"><i class="ri-lock-password-fill"></i>&nbsp;User end-to-end encryption</div>
              <div class="d-flex align-items-center pt-2"><i class="ri-lock-password-fill"></i>&nbsp;Leave no trace on our services</div>
              <div class="d-flex align-items-center pt-2"><i class="ri-lock-password-fill"></i>&nbsp;Have a self-detruct timer</div>
            </div>
          </div>
        </div>`
      }

      var groupInitMessage = function(message, room_id) {
        return `<div class='template init-message-wrapper-${ room_id }'>
          <div class='wrapper'>
            <p class='text-center'>${ message }</p>

            <div>Secret chat:</div>
            <div style='font-size: 14px;'>
              <div class='d-flex align-items-center pt-2'><i class='ri-lock-password-fill'></i>&nbsp;Advanced encryption standard</div>
              <div class='d-flex align-items-center pt-2'><i class='ri-lock-password-fill'></i>&nbsp;Leave no trace on our services</div>
              <div class='d-flex align-items-center pt-2'><i class='ri-lock-password-fill'></i>&nbsp;Have a self-detruct timer</div>
            </div>
          </div>
        </div>`
      }

      var renderListGroup = function(data, classPadding = "") {
        var photoIcon = ''
        if (data.room_type == 'zero_group') {
          photoIcon = `<i class="ri-ghost-line"></i>`
        } else {
          photoIcon = `<i class="ri-group-3-line"></i>`
        }

        return `<li data-long-press-delay="500" id="conversation-no-${ data.room_id }" class="${classPadding} conversation-no-${ data.room_id }">
            <div class="round">
              <input type="checkbox" disabled="disabled" class="room-check" id="checkbox-room-${ data.room_id }" />
              <label for="checkbox-room-${ data.room_id }"></label>
            </div>
            <a href="javascript:void(0)">
                <div class="each-conversation-content">
                  <div class="chat-user-img">
                    ${photoIcon}
                  </div>
                    <div class="flex-1 overflow-hidden">
                        <h5 class="text-truncate font-size-14 mb-0 room-name">#${ htmlEncode(data.group_name) }</h5>
                        <p class="chat-user-message text-truncate mb-0 chat-user-message-unread">${ htmlEncode(data.last_message) }</p>
                    </div>
                    <div class="unread-message">
                      <span class="is-unpin"></span>
                      <span class="badge badge-soft-danger rounded-pill"></span>
                    </div>
                </div>
            </a>
        </li>`
      }

      let current_user_id = parseInt(sessionStorage.getItem('current_user_id'))
      if (data.type == 'payment') {
        window.location.href = "/wallet"
        return true;
      }

      if (data.type == 'logout') {
        $.ajax({
          url: `/users/sign_out?delete_device=true`,
          type: "DELETE",
        });
        return true;
      }
      if (data.type == 'change_room_name') {
        $(`#wrapper-conversation-${data.roomId} .room-name`).text(`#${data.newName}`);
        $(`.conversation-no-${data.roomId} .room-name`).text(`#${data.newName}`)
        return true;
      }

      if (data.type == 'remove_as_mod') {
        if (data.remove_mod_ids.includes(current_user_id) && $(".add-member-button").attr("room_id") == data.room_id) {
          $(".add-member-button").remove();
          $(`li[user_id='${current_user_id}']`).find(".role-block").text("");
        }
        return true;
      }

      if (data.type == 'add_to_group') {
        this.updateMemberCount(data, current_user_id, roomList)
        this.addOrRemoveMemberNotification(data)

        if (data.added_list.includes(current_user_id)) {
          $.ajax({
            url: `/api/v1/conversations/listing?room_id=${data.room_id}`,
            type: "GET",
            async: true,
            success: function(data) {
              roomList.push(data.data)
            }
          });

          var group_type_class = data.room_type == 'group_chat' ? '.chat-group-list' : '.chat-zero-group-list'

          if ($(`${group_type_class} .pin-icon:last`).length) {
            $(`${group_type_class} .pin-icon:last`).parent().parent().parent().parent().after(renderListGroup(data, "px-2"))
          } else {
            $(group_type_class).prepend(renderListGroup(data, "px-2"));
          }
          handleInitMessageGroup()
        } else {
          this.setUsernameListForGroup(data.room_id)
        }
        return true
      }

      if (data.type == 'update_counter') {
        this.plus_each_conv(data.room_type, data.room_id)

        return true
      }


      if (data.type == 'create_group') {
        if (data.added_list.includes(current_user_id)) {
          roomList.push({
            roomType: data.room_type,
            id: data.room_id,
            memberCount: data.member_count,
            adminId: data.admin_id,
            initMessage: current_user_id === data.admin_id ? 'You have created a secret group chat' : 'You have invited to join a secret group chat',
            conversations: [],
            usersData: data.users_data
          });

          var group_type_class = data.room_type == 'group_chat' ? '.chat-group-list' : '.chat-zero-group-list'

          if ($(`${group_type_class} .pin-icon:last`).length) {
            $(`${group_type_class} .pin-icon:last`).parent().parent().parent().parent().after(renderListGroup(data, "px-2"))
          } else {
            $(group_type_class).prepend(renderListGroup(data, "px-2"));
          }

          $(".forward-group-list").prepend(renderListGroup(data));
          handleInitMessageGroup()
        }
        return true
      }

      if (data.type == 'remove_from_group') {
        this.updateMemberCount(data, current_user_id, roomList)
        if (data.remove_list.includes(current_user_id)) {
          this.delete_from_conversation(data.room_id, data.room_type)
        } else {
          this.addOrRemoveMemberNotification(data)
        }
        handleInitMessageGroup()
        return true
      }

      if (data.type == 'delete') {
        if (data.room_type !== 'dirrect') {
          this.updateMemberCount(data, current_user_id, roomList)
        }

        if (data.remove_list.includes(current_user_id)) {
          this.delete_from_conversation(data.room_id, data.room_type)
        } else {
          if (data.room_type !== 'dirrect') {
            this.addOrRemoveMemberNotification(data)
          }
        }
        return true;
      }

      if (data.type == 'clear') {
        let conversations = roomList.find(element => element.id === data.room_id).conversations
        // Check if current user is in mode remove both room's participants or not
        if (data.clear_user_ids.includes(current_user_id)) {
          conversations.splice(0, conversations.length);

          var chat_message = 'History was cleared'
          var initMessage = "No messages here yet..."
          var conversation_message = conversationInitMessage(initMessage, data.room_id)
          $(`#wrapper-conversation-${data.room_id} #my-chat-history`).html('');
          $(`#wrapper-conversation-${data.room_id} #my-chat-init-message`).html(conversation_message)
          $(".conversation-no-" + data.room_id).find('.unread-message span').html('')
          $(".conversation-no-" + data.room_id).find('.chat-user-message').removeClass('chat-user-message-unread')
          $(".conversation-no-" + data.room_id).find('.chat-user-message').html(chat_message)
          this.reload_statistic(data.room_type)
        }
        return true
      }

      if (data.type == 'remove-message') {
        if (data.user_id.includes(current_user_id)) {
          var conversationNo = $(`#conversation-no-${ data.room.id }`)
          var conversations = roomList.find(element => element.id === data.room.id).conversations

          // Reset number unread message in chat page
          if (data.read_status == 0) {
            conversationNo.find('.unread-message span').html('')
            conversationNo.find('.chat-user-message').removeClass('chat-user-message-unread')
          } else {
            conversationNo.find('.unread-message span').html(data.read_status)
          }

          data.ids.forEach(function(id) {
            $(`#message-${ id }`).remove()
            let dt = conversations.findIndex(dt => dt.id === id)
            if (dt !== -1) {
              conversations.splice(dt, 1);
            }
          })
          this.resetLastMessageOnRoom(conversations, data.room.id)

          if (conversations.length === 0) {
            var message = conversationInitMessage("No messages here yet...", data.room.id)
            conversationNo.find('.unread-message span').html('')
            conversationNo.find('.chat-user-message').removeClass('chat-user-message-unread')
            conversationNo.find('.chat-user-message').html("History was cleared")

            $(`#wrapper-conversation-${data.room.id} #my-chat-history`).html('')
            $(`#wrapper-conversation-${data.room.id} #my-chat-init-message`).html(message)
          }

          holdClick();
        }

        return true
      }

      if (data.type == 'plus') {
        $(`#wrapper-conversation-${data.room_id} span.send-status`).html(`<i class="ri-check-double-line"></i>`)

        let roomData = roomList.find(element => element.id === data.room_id)

        if (roomData) {
          let conversations = roomData.conversations;
          conversations.forEach(function(message) {
            message.notSeenCount = 0;
          })
        }

        if (data.read_user_id == current_user_id) {
          roomData.myReadStatus = {
            firstEl: null,
            count: 0
          }
        }

        return true
      }
      const white_list = data.receive_ids.concat(data.user_id)
      if (!white_list.includes(current_user_id)) {
        return true;
      }

      $(".my-chat-init-message-room-" + data.room_id).html("")

      // track when reading
      $(`#wrapper-conversation-${data.room_id} #my-chat-init-message`).html("")
      if (data.receive_ids.includes(current_user_id) && $('#my-chat-history').attr('room_id') == data.room_id) {
        $.ajax({
          url: `/conversations/set_read?room_id=${data.room_id}`,
          context: document.body
        }).done(function() {
        });
      }

      var roomData = roomList.find(element => element.id === data.room_id)
      var decryptMessage = data.message;

      if (roomData === undefined) {
        getRoomData(data.room_id)
        roomData = roomList.find(element => element.id === data.room_id)
      } else {
        let conversations = roomData.conversations
        let conversationsData = {
          id: data.content.id,
          message: decryptMessage,
          userId: data.content.user_id,
          createdAt: data.content.created_at,
          shortName: data.user_short_name,
          name: data.username,
          deleteDate: data.content.delete_date,
          notSeenCount: data.receive_ids.length,
          messageImages: data.image_urls,
          messageVoice: data.voice_url,
          messageFiles: data.file_names,
          messageType: data.content.message_type
        };

        if (data.reply_message) {
          conversationsData.replyMessageId = data.reply_message.id
          conversationsData.replyMessageType = data.reply_message.message_type;
          conversationsData.replyPhotoUrl = data.reply_photo_url;
          conversationsData.replyFileNames = data.reply_file_names
          conversationsData.replyMessageContent = data.reply_message_decrypted;
          conversationsData.replyMessageFullname = data.room_type == 'zero_group' ? data.reply_user.fake_name : data.reply_user.fullname;
        }

        if (data.content.author_name) {
          conversationsData.authorName = data.content.author_name;
        }
        var lastMessageObject = conversations.slice(-1)[0]

        conversations.push(conversationsData);
      }

      // process when create dirrect message
      if (data.room_type == 'dirrect' && $(".chat-user-list #conversation-no-" + data.room_id).length == 0) {
        const dirrectUserId = data.content.user_id === current_user_id ? data.dirrect_user_id : data.user_id;

        const customName = $(`.custom-fullname-${dirrectUserId}:first`).text();
        const customColor = $(`.custom-color-value-${dirrectUserId}:first`).val();
        const customAvatar = $(`.custom-avatar-value-${dirrectUserId}:first`).val();

        let initCount = "";
        if (data.receive_ids.includes(current_user_id) && $('#my-chat-history').attr('room_id') != data.room_id) {
          initCount = 1;
        }

        const roomListContent = function(classPadding) {
          return `
            <li data-long-press-delay="500" id="conversation-no-${data.room_id}" class="${classPadding} conversation-no-${ data.room_id }">
              <div class="round">
                <input type="checkbox" disabled="disabled" class="room-check" id="checkbox-room-${ data.room_id }" />
                <label for="checkbox-room-${ data.room_id }"></label>
              </div>
              <a href="javascript:void(0)">
                <div class="each-conversation-content">
                  <img class="avatar-img custom-color-${dirrectUserId}" style="border-color: ${customColor}" src="${customAvatar}">
                  <div class="flex-1 overflow-hidden">
                    <h5 class="text-truncate font-size-15 mb-1 room-name custom-fullname-${dirrectUserId}">${ htmlEncode(customName) }</h5>
                    <p class="chat-user-message chat-user-message-unread text-truncate mb-0">${htmlEncode(decryptMessage)}</p>
                  </div>
                  <div class="font-size-11"></div>
                  <div class="unread-message">
                    <span class="is-unpin"></span>
                    <span class="badge badge-soft-danger rounded-pill">${ initCount }</span>
                  </div>
                </div>
              </a>
            </li>
          `
        }
        if ($(".chat-user-list .pin-icon:last").length) {
          $(".chat-user-list .pin-icon:last").parent().parent().parent().parent().after(roomListContent("px-2"))
        } else {
          $(".chat-user-list").prepend(roomListContent("px-2"));
        }

        $(".forward-group-list").prepend(roomListContent());
        handleInitMessageGroup()
      }

      const myReadStatus = roomData.myReadStatus
      const firstEl = myReadStatus ? myReadStatus.firstEl : null
      let countUnread = myReadStatus ? myReadStatus.count : 0
      if (data.receive_ids.includes(current_user_id)) {
        if (!firstEl) {
          roomData.myReadStatus = {}
          roomData.myReadStatus.firstEl = data.content.id
        }
        roomData.myReadStatus.count = countUnread + 1
      }

      // process on listing conversation or group
      if (data.room_type == "dirrect" || data.room_type == "broadcast") {
        if ($(`#conversation-no-${data.room_id} .pin-icon`).length == 0) {
          if ($(".chat-user-list .pin-icon:last").length) {
            $("#conversation-no-" + data.room_id).insertAfter($(".chat-user-list .pin-icon:last").parent().parent().parent().parent());
          } else {
            $("#conversation-no-" + data.room_id).prependTo(".chat-user-list")
          }
        }
        $(".chat-user-list #conversation-no-" + data.room_id + " .chat-user-message").addClass("chat-user-message-unread")
      } else if (data.room_type == "group_chat") {
        if ($(`#conversation-no-${data.room_id} .pin-icon`).length == 0) {
          if ($(".chat-group-list .pin-icon:last").length) {
            $("#conversation-no-" + data.room_id).insertAfter($(".chat-group-list .pin-icon:last").parent().parent().parent().parent());
          } else {
            $("#conversation-no-" + data.room_id).prependTo(".chat-group-list")
          }
        }

        $(".chat-group-list #conversation-no-" + data.room_id + " .chat-user-message").addClass("chat-user-message-unread")
      } else if (data.room_type == "zero_group") {
        if ($(`#conversation-no-${data.room_id} .pin-icon`).length == 0) {
          if ($(".chat-zero-group-list .pin-icon:last").length) {
            $("#conversation-no-" + data.room_id).insertAfter($(".chat-zero-group-list .pin-icon:last").parent().parent().parent().parent());
          } else {
            $("#conversation-no-" + data.room_id).prependTo(".chat-zero-group-list")
          }
        }

        $(".chat-zero-group-list #conversation-no-" + data.room_id + " .chat-user-message").addClass("chat-user-message-unread")
      }
      $(".forward-group-list #conversation-no-" + data.room_id).prependTo(".forward-group-list")


      let lastMessage = "";

      switch(data.content.message_type) {
        case 'image':
          lastMessage = 'Gallery'
          break;
        case 'voice':
          lastMessage = 'Voice message'
          break;
        case 'file':
          lastMessage = 'File'
          break;
        case 'csv':
          lastMessage = 'Secured book'
          break;
        case 'poll':
          lastMessage = 'Poll'
          break;
        default:
          lastMessage = decryptMessage
      };

      $("#conversation-no-" + data.room_id + " .chat-user-message").text(this.hiddenUsername(lastMessage))

      // count up when received people not in conversation
      if ($('#my-chat-history').attr('room_id') != data.room_id && data.receive_ids.includes(current_user_id)) {
        var count = 0
        if (data.read_status.find(s => s.user_id == current_user_id)) {
          count = data.read_status.find(s => s.user_id == current_user_id).count
        }
        $("#conversation-no-" + data.room_id + " .unread-message span.rounded-pill").text(count)
        this.plus_statistic(data.room_type)
      }

      if (data.user_id == current_user_id) {
        $(`#conversation-no-${data.room_id} .chat-user-message`).removeClass("chat-user-message-unread")
      }

      // show new message on conversation
      if($('#my-chat-history').attr('room_id') == data.room_id) {
        const customName = data.room_type == 'zero_group' ? data.username : $(`.custom-fullname-${data.user_id}:first`).text();
        var lifetime = data.lifetime;
        var authorName = '';
        var reply_message = '';

        if (data.content.author_name != null) {
          authorName = `
            <p class="mb-2 text-success border-bottom">
              Forwarded message
              <br/>
              From <b>${ htmlEncode(data.content.author_name) }</b>
            </p>
          `
        }

        if (data.reply_message) {
          if (data.reply_message.message_type === "voice") {
            reply_message = `<div class='border-bottom reply-section-content' reply-id="${data.reply_message.id}">
              <p class="text-success">${ htmlEncode(data.room_type == 'zero_group' ? data.reply_user.fake_name : data.reply_user.fullname) }</p>
              <p class="poll-icon-text"><i class="ri-mic-line chart-icon"></i> Voice message</p>
            </div>`
          } else if (data.reply_message.message_type === "poll") {
            reply_message = `<div class='border-bottom reply-section-content' reply-id="${data.reply_message.id}">
              <p class="text-success">${ htmlEncode(data.room_type == 'zero_group' ? data.reply_user.fake_name : data.reply_user.fullname) }</p>
              <p class="poll-icon-text"><i class="ri-pie-chart-2-line chart-icon"></i> Poll</p>
            </div>`
          } else if (data.reply_message.message_type === "image") {
            reply_message = `<div class='border-bottom reply-section-content reply-section-content-photo' reply-id="${data.reply_message.id}">
              <div class="image-block">
                ${ data.reply_photo_url[1] == 'video' ? `<video src="${data.reply_photo_url[0]}"></video>` : `<image src="${data.reply_photo_url[0]}" />` }
              </div>
              <div class="right-block">
                <p class="text-success">${ htmlEncode(data.room_type == 'zero_group' ? data.reply_user.fake_name : data.reply_user.fullname) }</p>
                <p>Gallery</p>
              </div>
            </div>`
          } else if (data.reply_message.message_type === "file") {
            reply_message = `<div class='border-bottom reply-section-content' reply-id="${data.reply_message.id}">
              <p class="text-success">${ htmlEncode(data.room_type == 'zero_group' ? data.reply_user.fake_name : data.reply_user.fullname) }</p>
              <p class="poll-icon-text"><i class="ri-file-line chart-icon"></i>${ htmlEncode(data.reply_file_names) }</p>
            </div>`
          } else if (data.reply_message.message_type === "csv") {
            reply_message = `<div class='border-bottom reply-section-content' reply-id="${data.reply_message.id}">
              <p class="text-success">${ htmlEncode(data.room_type == 'zero_group' ? data.reply_user.fake_name : data.reply_user.fullname) }</p>
              <p class="poll-icon-text"><i class="ri-file-excel-2-line chart-icon"></i>${ htmlEncode(data.reply_file_names) }</p>
            </div>`
          } else {
            reply_message = `<div class='border-bottom reply-section-content' reply-id="${data.reply_message.id}">
              <p class="text-success">${ htmlEncode(data.room_type == 'zero_group' ? data.reply_user.fake_name : data.reply_user.fullname) }</p>
              <p>${ htmlEncode(data.reply_message_decrypted) }</p>
            </div>`
          }
        }

        const parentThis = this;
        const messageCreatedAt = moment.utc(data.content.created_at).tz(curentTimezone)
        const timeSent = messageCreatedAt.format("HH:mm");
        const dateSent = messageCreatedAt.format("MMMM Do");
        const isAtBottom = $("#my-chat-history").height() - ($("#user-chat-show .simplebar-content-wrapper").scrollTop() + $(".chat-conversation").height()) < 50
        let lastDateSent = ""

        if (lastMessageObject) {
          lastDateSent = moment.utc(lastMessageObject.createdAt).tz(curentTimezone).format("MMMM Do");
        }

       if (data.content.message_type == 'text') {
          var avatarContent = ``
          if (data.user_id !== current_user_id) {
            const avatarPath = roomData.usersData.find((user) => user.id == data.user_id).avatar
            avatarContent = `<div class="chat-avatar">
              <img src="${avatarPath}" alt="">
            </div>`
          }
          const liData =
            `<div class="round">
               <input type="checkbox" disabled="disabled" class="message-check" id="checkbox-${ data.content.id }" />
               <label for="checkbox-${ data.content.id }"></label>
             </div>
             <div class="conversation-list">
                ${avatarContent}
                <div class="right-block">
                  <div class="user-chat-content">
                    <div class="ctext-wrap">
                      <div class="ctext-wrap-content">
                        ${ data.user_id != current_user_id ? `<div class="conversation-name custom-fullname-${data.user_id}">${ data.room_type == 'broadcast' ? "" : htmlEncode(customName || data.username) }</div>` : '' }
                        ${ reply_message }
                        ${ authorName }
                        <p class="mb-0 message-content">${htmlEncodeMessage(decryptMessage)}</p>
                      </div>
                    </div>
                    <div class="chat-time mb-0">
                    <span class="display-countdown-lifetime">${ lifetime }</span>
                    <span class="align-middle">${timeSent}</span>
                    ${data.receive_ids.includes(current_user_id) || data.room_type == 'broadcast' ? '' : '<span class="align-middle send-status"><i class="ri-check-line"></i></span>'}
                  </div>
                </div>
                ${ ["group_chat", "zero_group"].includes(data.room_type) ? '<p class="seen-user-list">...</p>' : '' }
              </div>
            </div>`


          if (data.user_id == current_user_id) {
            const newLi = $(`.message-token-${data.message_token}`)
            if (newLi.length) {
              newLi.attr("message-type", data.content.message_type)
              newLi.attr("id", `message-${ data.content.id }`)
              newLi.html(liData)
            } else {
              $(`#my-chat-history[room_id='${data.room_id}']`).append(`
                <li data-long-press-delay="500" class="right conversation-messages" message-type="${ data.content.message_type }" id="message-${ data.content.id }">
                  ${liData}
                </li>
              `)
            }
          } else {
            $('#my-chat-history').append(`
              <li data-long-press-delay="500" class="left conversation-messages" message-type="${ data.content.message_type }" id="message-${ data.content.id }">
                ${liData}
               </li>
            `)
          }

          if (data.room_type === 'group_chat' || data.room_type === 'zero_group') {
            const newMessage = htmlEncodeMessage(decryptMessage).replace(/\B@\w+/g, function(match, tag, content) {
              const userId = match.slice(1)
              const fullName = $(`.custom-fullname-${userId}:first`).text()

              if (fullName) {
                return `<a class="mention-data" data-id= "${userId}" href="javascript:void(0)">${fullName}</a> `
              }
              return match;
            })

            $(`#message-${data.content.id} .message-content`).html(newMessage)


            const newReplyMessage = htmlEncode(data.reply_message_decrypted).replace(/\B@\w+/g, function(match, tag, content) {
              const userId = match.slice(1)
              const fullName = $(`.custom-fullname-${userId}:first`).text()

              if (fullName) {
                return `<a class="mention-data" data-id= "${userId}" href="javascript:void(0)">${fullName}</a> `
              }
              return match;
            })

            $(`#message-${data.content.id} .reply-section-content p:not([class])`).html(newReplyMessage)
          }

          handleLongPress($(`#message-${data.content.id}`));
          handleSelectMessage($(`#message-${data.content.id}`));
          $(".round").removeClass("open");
          if (data.content.delete_date) {
            parentThis.displayLifeTime($(`#message-${ data.content.id }`), data.content.delete_date, roomData)
          }
          if (data.content.user_id === current_user_id) {
            messages_to_bottom()
          } else if (isAtBottom) {
            messages_to_bottom()
          }
        } else if (data.content.message_type === 'image') {
          $(".loading-text-block").remove();
          var imageSource = "";
          const imageSize = data.image_urls.length;
          let temp = 0;
          var authorName = '';

          if (data.content.author_name != null) {
            authorName = `
              <p class="mb-2 text-success border-bottom">
                Forwarded message
                <br />
                From <b>${ htmlEncode(data.content.author_name) }</b>
              </p>
            `
          }


          data.image_urls.forEach(function(image) {
            const imageData = image[0]
            const fileType = image[1]
            if (temp % 2 === 0) {
              imageSource += `<ul class="list-inline message-img mb-0 row">`
            }
            if (fileType == 'video') {
              imageSource += `
                <li class="list-inline-item message-img-list ${imageSize === 1 ? 'col-12 only-one-image' : 'col-6'} each-message-photo">
                  <div>
                    <div class="view-photo d-inline-block" raw_image="${imageData}">
                      <video src="${imageData}" alt="" class="rounded border"></video>
                      <label class="is-video">Video</label>
                    </div>
                  </div>
              </li>`
            } else {
              imageSource += `
                <li class="list-inline-item message-img-list ${imageSize === 1 ? 'col-12 only-one-image' : 'col-6'} each-message-photo">
                  <div>
                    <div data-fancybox="${data.room_id}" data-src="${imageData}" class="view-photo d-inline-block">
                      <img src="${imageData}" class="rounded border">
                    </div>
                  </div>
              </li>`
            }
            if (temp % 2 !== 0 || temp === imageSize - 1) {
              imageSource += `</ul>`
            }
            temp ++;
          });
          var avatarContent = ``
          if (data.user_id !== current_user_id) {
            const avatarPath = roomData.usersData.find((user) => user.id == data.user_id).avatar
            avatarContent = `<div class="chat-avatar">
              <img src="${avatarPath}" alt="">
            </div>`
          }

          $('#my-chat-history').append(`
          <li data-long-press-delay="500" class="${data.user_id == current_user_id ? 'right' : 'left'} conversation-messages" message-type="${ data.content.message_type }" id="message-${ data.content.id }">
            <div class="round">
              <input type="checkbox" disabled="disabled" class="message-check" id="checkbox-${ data.content.id }" />
              <label for="checkbox-${ data.content.id }"></label>
            </div>
            <div class="conversation-list">
              ${avatarContent}
              <div class="right-block">
                <div class="user-chat-content">
                  <div class="ctext-wrap">
                    <div class="ctext-wrap-content">
                      ${ data.user_id != current_user_id ? `<div class="conversation-name custom-fullname-${data.user_id}">${ data.room_type == 'broadcast' ? "" : htmlEncode(customName || data.username) }</div>` : '' }
                      ${ authorName }
                      <p class="mb-0 message-content">${htmlEncode(decryptMessage)}</p>
                      ${imageSource}
                    </div>
                  </div>
                  <div class="chat-time mb-0">
                    <span class="display-countdown-lifetime">${ lifetime }</span>
                    <span class="align-middle">${timeSent}</span>
                    ${data.receive_ids.includes(current_user_id) || data.room_type == 'broadcast' ? '' : '<span class="align-middle send-status"><i class="ri-check-line"></i></span>'}
                  </div>
                </div>
                ${ ["group_chat", "zero_group"].includes(data.room_type) ? '<p class="seen-user-list">...</p>' : '' }
              </div>
            </div>
          </li>`);
        } else if (data.content.message_type === 'voice') {
          $(".loading-voice-block").remove();
          $(".loading-text-block").remove();
          var authorName = '';

          if (data.content.author_name != null) {
            authorName = `
              <p class="mb-2 text-success border-bottom">
                Forwarded message
                <br />
                From <b>${ htmlEncode(data.content.author_name) }</b>
              </p>
            `
          }

          var avatarContent = ``
          if (data.user_id !== current_user_id) {
            const avatarPath = roomData.usersData.find((user) => user.id == data.user_id).avatar
            avatarContent = `<div class="chat-avatar">
              <img src="${avatarPath}" alt="">
            </div>`
          }

          $('#my-chat-history').append(`
            <li data-long-press-delay="500" class="${data.user_id == current_user_id ? 'right' : 'left'} conversation-messages" message-type="${ data.content.message_type }" id="message-${ data.content.id }">
              <div class="round">
                <input type="checkbox" disabled="disabled" class="message-check" id="checkbox-${ data.content.id }" />
                <label for="checkbox-${ data.content.id }"></label>
              </div>
              <div class="conversation-list">
                ${avatarContent}
                <div class="right-block">
                  <div class="user-chat-content">
                    <div class="ctext-wrap">
                      <div class="ctext-wrap-content">
                        ${ data.user_id != current_user_id ? `<div class="conversation-name custom-fullname-${data.user_id}">${ data.room_type == 'broadcast' ? "" : htmlEncode(customName || data.username) }</div>` : '' }
                        ${ authorName }
                        <div class="voice-block">
                          <div class="media-button">
                            <i class="ri-play-fill"></i>
                            <i class="ri-pause-fill"></i>
                          </div>
                          <div class="wave-block" id="voice-message-${ data.content.id }"></div>
                          <div class="voice-duration voice-duration-${data.content.id}"></div>
                        </div>
                      </div>
                    </div>

                    <div class="chat-time mb-0">
                      <span class="display-countdown-lifetime">${ lifetime }</span>
                      <span class="align-middle">${timeSent}</span>
                      ${data.receive_ids.includes(current_user_id) || data.room_type == 'broadcast' ? '' : '<span class="align-middle send-status"><i class="ri-check-line"></i></span>'}
                    </div>
                  </div>
                  ${ ["group_chat", "zero_group"].includes(data.room_type) ? '<p class="seen-user-list">...</p>' : '' }
                </div>
              </div>
            </li>
          `)
        } else if (data.content.message_type === 'file' || data.content.message_type === 'csv') {
          $(".loading-text-block").remove();

          let fileSource = '';
          var authorName = '';

          if (data.content.author_name != null) {
            authorName = `
              <p class="mb-2 text-success border-bottom">
                Forwarded message
                <br />
                From <b>${ htmlEncode(data.content.author_name) }</b>
              </p>
            `
          }

          data.file_names.forEach(function(file) {
            fileSource += `
              <div class="${ data.content.message_type === 'csv' ? "each-secured-book" : 'each-file' }" token="${ file['token'] }">
                <div class="file-icon">
                  <i class="${ data.content.message_type === 'csv' ? 'ri-file-excel-2-fill' : 'ri-file-fill'}"></i>
                </div>
                <div class="file-detail">
                  <p>${ htmlEncode(file['title']) }.${ data.content.message_type === 'csv' ? "csv" : 'txt' }</p>
                </div>
              </div>
            `
          });

          var avatarContent = ``
          if (data.user_id !== current_user_id) {
            const avatarPath = roomData.usersData.find((user) => user.id == data.user_id).avatar
            avatarContent = `<div class="chat-avatar">
              <img src="${avatarPath}" alt="">
            </div>`
          }

          $('#my-chat-history').append(`
            <li data-long-press-delay="500" class="${data.user_id == current_user_id ? 'right' : 'left'} conversation-messages" message-type="${ data.content.message_type }" id="message-${ data.content.id }">
              <div class="round">
                <input type="checkbox" disabled="disabled" class="message-check" id="checkbox-${ data.content.id }" />
                <label for="checkbox-${ data.content.id }"></label>
              </div>
              <div class="conversation-list">
                ${avatarContent}
                <div class="right-block">
                  <div class="user-chat-content">
                    <div class="ctext-wrap">
                      <div class="ctext-wrap-content">
                        ${ data.user_id != current_user_id ? `<div class="conversation-name custom-fullname-${data.user_id}">${ data.room_type == 'broadcast' ? "" : htmlEncode(customName || data.username) }</div>` : '' }
                        ${ authorName }
                        <p class="mb-1 message-content">${ htmlEncode(decryptMessage)}</p>
                        ${fileSource}
                      </div>
                    </div>

                    <div class="chat-time mb-0">
                      <span class="display-countdown-lifetime">${ lifetime }</span>
                      <span class="align-middle">${timeSent}</span>
                      ${data.receive_ids.includes(current_user_id) || data.room_type == 'broadcast' ? '' : '<span class="align-middle send-status"><i class="ri-check-line"></i></span>'}
                    </div>
                  </div>
                  ${ ["group_chat", "zero_group"].includes(data.room_type) ? '<p class="seen-user-list">...</p>' : '' }
                </div>
              </div>
          </li>`);
        }

        if (data.content.message_type !== 'text') {
          if (data.content.delete_date) {
            this.displayLifeTime($(`#message-${ data.content.id }`), data.content.delete_date, roomData)
          }

          if (data.content.user_id === current_user_id) {
            messages_to_bottom()
          } else if (isAtBottom) {
            messages_to_bottom()
          }
          // Call function hold click for delete specific message
          handleLongPress($(`#message-${data.content.id}`));
          handleSelectMessage($(`#message-${data.content.id}`));
          $(".round").removeClass("open");
          this.setWaveForVoice(data)
        }
        if (lastDateSent !== dateSent) {
          $(`#message-${ data.content.id }`).before(`<li class="date-break-line"><p class="date-content">${dateSent}</p></li>`);
        }
      }
    },
    setUsernameListForGroup(roomId) {
      const roomData = roomList.find(element => element.id === roomId)
      $.ajax({
        url: `/api/v1/users/room_member?room_id=${roomId}&show_all=true`,
        type: "GET",
        async: true,
        success: function(userList) {
          const customUsername = userList.map(user => `<div class="d-none custom-fullname-${user.username}">${user.fullname}</div>`)
          $("form#message-content .user-name-list").html(customUsername)
          roomData.usersData = userList
        }
      })
    },
    hiddenUsername(html) {
      let newString = html.replace(/\B@\w+/g, '@hidden_user')
      return newString
    },
    setWaveForVoice(data) {
      const formatTimecode = seconds => {
        return new Date(seconds * 1000).toISOString().substr(14, 5)
      }
      if (data.voice_url) {
        const wavesurfer = WaveSurfer.create({
          container: `#voice-message-${data.content.id}`,
          waveColor: 'white',
          progressColor: '#bdc0c2'
        });
        wavesurfer.setHeight(36.5)

        wavesurfer.load(data.voice_url);
        $(`#message-${data.content.id} .ri-play-fill`).on("click", function() {
          wavesurfer.play();
        })

        $(`#message-${data.content.id} .ri-pause-fill`).on("click", function() {
          wavesurfer.pause();
        })

        wavesurfer.on("pause", function() {
          $(`#message-${data.content.id} .ri-play-fill`).show();
          $(`#message-${data.content.id} .ri-pause-fill`).hide();
        });

        wavesurfer.on("play", function() {
          $(`#message-${data.content.id} .ri-play-fill`).hide();
          $(`#message-${data.content.id} .ri-pause-fill`).show();
        });
        wavesurfer.on("ready", () => {
          $(`.voice-duration-${data.content.id}`).text(formatTimecode(wavesurfer.getDuration()));
        });
        wavesurfer.on('audioprocess', function() {
          if (wavesurfer.isPlaying()) {
            var totalTime = wavesurfer.getDuration(),
            currentTime = wavesurfer.getCurrentTime(),
            remainingTime = totalTime - currentTime;
            $(`.voice-duration-${data.content.id}`).text(formatTimecode(remainingTime));

            if (remainingTime === 0) {
              $(`.voice-duration-${data.content.id}`).text(formatTimecode(totalTime));
            }
          }
        });
        wavesurfer.on('finish', function() {
          $(`.voice-duration-${data.content.id}`).text(formatTimecode(wavesurfer.getDuration()));
        });
        $(".user-chat-remove").on("click", function() {
          wavesurfer.destroy();
        })
      }
    },
    displayLifeTime(el, val, roomData) {
      const current = this;
      var countDownDate = new Date(val).getTime();
      var displayCountDownLifeTime = el.find('.display-countdown-lifetime');
      var id = el.attr('id').split('message-')[1];

      var x = setInterval(function() {
        let now = new Date().getTime() - globalShiftTime
        var distance = (countDownDate - now) / 1000;
        var days = Math.floor(distance / (3600*24));
        var hours = Math.floor(distance % (3600*24) / 3600);
        var minutes = Math.floor(distance % 3600 / 60);

        var time = days > 0 ? `${ days + 1 }d` : hours > 0 ? `${ hours + 1}h` : `${ minutes + 1}m`;
        displayCountDownLifeTime.html(time);

        if (distance < 0) {
          $(el).remove();
          let conversations = roomData.conversations
          let dt = conversations.findIndex(dt => dt.id == id)

          if (dt === -1) {
            clearInterval(x);
            return;
          }

          conversations.splice(dt, 1);
          current.resetLastMessageOnRoom(conversations, roomData.id)
          clearInterval(x);
        }
      }, 1000);
    },
    resetLastMessageOnRoom(conversations, roomId) {
      var last_message_content = '';
      let last_message_index = conversations.length - 1
      if (last_message_index >= 0 ) {
        const last_message = conversations[last_message_index]

        if (last_message.poll) {
          last_message_content = "Poll card";
        } else if (last_message.messageImages) {
          last_message_content = "Photo";
        } else if (last_message.messageVoice) {
          last_message_content = "Voice message";
        } else if (last_message.messageFiles) {
          last_message_content = "File";
        } else {
          last_message_content = last_message.message
        }
      } else {
        last_message_content = 'no message'
      }
      $(`#conversation-no-${roomId} .chat-user-message`).text(last_message_content)
    },
    updateMemberCount(data, current_user_id, roomList) {
      if (data.update_member_count_list.includes(current_user_id)) {
        $(`#wrapper-conversation-${data.room_id} .member-count`).text(`${data.member_count} members`);
        roomList.find(element => element.id === data.room_id).memberCount = data.member_count;
      }
    },

    delete_from_conversation(room_id, room_type) {
      if ($('#my-chat-history').attr('room_id') == room_id) {
        $(`#wrapper-conversation-${room_id} .user-chat-remove`).click();
      }

      let room_index = roomList.findIndex(element => element.id === room_id)
      if (room_index != -1) {
        roomList.splice(room_index, 1)
      }

      $(".conversation-no-" + room_id).remove()
      this.reload_statistic(room_type)
      handleInitMessageGroup()
    },

    plus_statistic(room_type) {
      if (room_type == "dirrect") {
        if ($("#dirrect-message-counter.notify-counter").length == 0) {
          $("#dirrect-message-counter").addClass("notify-counter")
          $("#dirrect-message-counter").text(0)
        }
        $("#dirrect-message-counter").text(parseInt($("#dirrect-message-counter").text()) + 1);
      } else if (room_type == "group_chat") {
        if ($("#group-message-counter.notify-counter").length == 0) {
          $("#group-message-counter").addClass("notify-counter")
          $("#group-message-counter").text(0)
        }
        $("#group-message-counter").text(parseInt($("#group-message-counter").text()) + 1);
      } else if (room_type == "zero_group") {
        if ($("#zero-group-message-counter.notify-counter").length == 0) {
          $("#zero-group-message-counter").addClass("notify-counter")
          $("#zero-group-message-counter").text(0)
        }
        $("#zero-group-message-counter").text(parseInt($("#zero-group-message-counter").text()) + 1);
      }
    },
    plus_each_conv(room_type, room_id) {
      var rootClass
      const self = this

      if (room_type == "dirrect") {
        rootClass = ".chat-user-list"
      } else if (room_type == "group_chat") {
        rootClass = ".chat-group-list"
      } else if (room_type == "zero_group") {
        rootClass = ".chat-zero-group-list"
      }

      var countEl = $(`${rootClass} #conversation-no-${room_id} .rounded-pill`)

      $(`${rootClass} #conversation-no-${room_id} .chat-user-message`).addClass("chat-user-message-unread")

      setTimeout(function() {
        if (countEl.text()) {
          countEl.text(parseInt(countEl.text()) + 1)
        } else {
          countEl.text(1)
        }
        self.reload_statistic(room_type)
      }, 1000);
    },
    reload_statistic(room_type) {
      if (room_type == 'dirrect') {
        var total = 0
        $(".chat-user-list .rounded-pill").each(function(e) {
          if ($(this).text()) {
            total += parseInt($(this).text());
          }
        })
        if (total > 0) {
          $("#dirrect-message-counter").addClass("notify-counter")
          $("#dirrect-message-counter").text(total)
        } else {
          $("#dirrect-message-counter").removeClass("notify-counter")
          $("#dirrect-message-counter").text("")
        }
      } else if (room_type == 'group_chat') {
        var total_group = 0
        $(".chat-group-list .rounded-pill").each(function(e) {
          if ($(this).text()) {
            total_group += parseInt($(this).text());
          }
        })
        if (total_group > 0) {
          $("#group-message-counter").addClass("notify-counter")
          $("#group-message-counter").text(total_group)
        } else {
          $("#group-message-counter").removeClass("notify-counter")
          $("#group-message-counter").text("")
        }
      } else if (room_type == 'zero_group') {
        var total_group = 0
        $(".chat-zero-group-list .rounded-pill").each(function(e) {
          if ($(this).text()) {
            total_group += parseInt($(this).text());
          }
        })
        if (total_group > 0) {
          $("#zero-group-message-counter").addClass("notify-counter")
          $("#zero-group-message-counter").text(total_group)
        } else {
          $("#zero-group-message-counter").removeClass("notify-counter")
          $("#zero-group-message-counter").text("")
        }
      }
    },
    processSyncData() {
      const self = this
      var roomIdsArray = roomList.map((roomObject) => roomObject.id)

      roomIdsArray.forEach(function(id) {
        const roomData = roomList.find( object => object.id == id)

        $.ajax({
          url: `/api/v1/conversations/listing?room_id=${id}`,
          type: "GET",
          async: true,
          success: function(data) {
            const newRoomData = data.data

            if (newRoomData) {
              const index = roomList.indexOf(roomData);
              roomList.splice(index, 1);
              roomList.push(newRoomData)
              const roomOpenningId = $("#my-chat-history").attr("room_id")

              if (id == roomOpenningId) {
                const currentUserId = parseInt(sessionStorage.getItem('current_user_id'))
                handleAddMoreData(newRoomData, currentUserId, false)
              }
            } else {
              self.delete_from_conversation(id, "")
            }
          }
        });
      })
    },
    syncContactList() {
      const self = this
      $.ajax({
        url: '/contact/sync_contact',
        type: 'GET',
        dataType : 'script',
        success: function() {
          const counter = $("#pills-requests .contact-list li").length;
          if (counter > 0) {
            $(".friend-request-counter").addClass("notify-counter");
            $(".friend-request-counter").text(counter);
            $("#pills-requests-tab").show();

            $("#pills-requests-tab").on("click", function() {
              activaTab("pills-requests", "pills-requests");
            })
            $(".back-to-contact-btn").on("click", function() {
              activaTab("pills-contacts", "pills-contacts");
            })
          } else {
            $(".friend-request-counter").removeClass("notify-counter");
            $("#pills-requests-tab").hide();
          }

          self.sortContact()
        }
      });
    },
    sortContact() {
      var mylist = $('#pills-contacts .contact-list');
      var listitems = mylist.children('li').get();
      listitems.sort(function(a, b) {
        return $(a).find(".room-name").text().toUpperCase().localeCompare($(b).find(".room-name").text().toUpperCase());
      })
      $.each(listitems, function(idx, itm) { mylist.append(itm); });
    },
    syncGroup() {
      const self = this
      $.ajax({
        url: '/groups/sync_group',
        type: 'GET',
        dataType : 'script',
        success: function() {
          self.reload_statistic('dirrect')
          self.reload_statistic('group_chat')
          self.reload_statistic('zero_group')
          resetHandleOnGroup()
          resetHandlePin()
        }
      });
    },
    addOrRemoveMemberNotification(data) {
      // start notification added member
      $(`#my-chat-history[room_id='${data.room_id}']`).append(`
        <li class="date-break-line" id="message-${data.content.id}"><p class="date-content">${htmlEncode(data.system_message)}</p></li>
      `)
      $(`#wrapper-conversation-${data.room_id} #my-chat-init-message`).html("")

      $("#conversation-no-" + data.room_id + " .chat-user-message").text(htmlEncode(data.system_message))

      let roomData = roomList.find(element => element.id === data.room_id)
      if (roomData) {
        let conversations = roomData.conversations
        let conversationsData = {
          id: data.content.id,
          message: data.system_message,
          userId: data.content.user_id,
          createdAt: data.content.created_at,
          deleteDate: data.content.delete_date,
          messageType: data.content.message_type
        };
        conversations.push(conversationsData);
      }
      // end notification
    }
  })
});
